import {
  GET_LATEST_DEALS_SUCCESS,
  GET_LATEST_DEALS_FAILURE,
} from '../actions/deals'
import { LOGOUT_USER } from '../actions/user'

const INITIAL_STATE = {
  data: [],
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_LATEST_DEALS_SUCCESS: {
      const newState = {
        ...state,
        data: action.payload.data,
      }
      return newState
    }

    case LOGOUT_USER: {
      const newState = Object.assign({}, INITIAL_STATE)
      return newState
    }

    case GET_LATEST_DEALS_FAILURE:

    default: {
      return state
    }
  }
}
