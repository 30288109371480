/* eslint-disable import/prefer-default-export */
// read from store

const getUser = state => state.user
const getLanguage = state => state.user.language
const getDeals = state => state.deals
const getAlerts = state => state.alerts
const getCashouts = state => state.cashout

export { getUser, getLanguage, getDeals, getAlerts, getCashouts }
