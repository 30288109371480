import {
  CHANGE_MODAL_HOWITWORKS,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  CHANGE_LANGUAGE,
  LOGOUT_USER,
  FACEBOOK_LOGIN_SUCCESS,
  FACEBOOK_LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  GET_ME_SUCCESS,
  GET_ME_FAILURE,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  UPDATE_PROFILE_PIC_SUCCESS,
  UPDATE_PROFILE_PIC_FAILURE,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAILURE,
} from '../actions/user'
import {
  FORGOT_SUCCESS,
  FORGOT_FAILURE,
  RESET_SUCCESS,
  RESET_FAILURE,
} from '../actions/forgot'

import localization from '../constants/localization'

const INITIAL_STATE = {
  language: localization.defaultLanguage,
  error: false,
  modalHowItWorksShown: false,
  data: {},
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    //hide modal_howItWorks once visiting the deals page
    case CHANGE_MODAL_HOWITWORKS: {
      const newState = {
        ...state,
        modalHowItWorksShown: true,
      }
      return newState
    }

    //updat the date when update user

    case UPDATE_PROFILE_SUCCESS: {
      const newState = {
        ...state,
        data: {
          ...state.data, // keep other data in state of INITIAL_STATE.data
          ...action.payload.data, // keep user's data in user.INITIAL_STATE.data
          user: action.payload.data.user, //update user.INITIAL_STATE.data.user
        },
      }
      return newState
    }

    case UPDATE_PROFILE_PIC_SUCCESS: {
      const newState = {
        ...state,
        data: {
          ...state.data, // keep other data in state of INITIAL_STATE.data
          ...action.payload.data, // keep user's data in user.INITIAL_STATE.data
          user: action.payload.data.user, //update user.INITIAL_STATE.data.user
        },
      }
      return newState
    }

    // get user info with a token
    case GET_ME_SUCCESS: {
      const newState = {
        ...state,
        data: {
          ...state.data, // keep other data in state of INITIAL_STATE.data
          ...action.payload.data, // keep user's data in INITIAL_STATE.data.user
          user: action.payload.data.user, //update INITIAL_STATE.data.user
        },
      }
      return newState
    }

    case CHANGE_LANGUAGE: {
      const newState = {
        ...state,
        language: action.payload.language,
      }
      return newState
    }

    //FORGOT_SUCCESS => STAY ON THE SAME PAGE
    //FORGOT_SUCCESS => STAY ON THE SAME PAGE
    //RESET_PASSWORD_SUCCESS => STAY ON THE SAME PAGE

    case RESET_SUCCESS:
    case LOGIN_SUCCESS:
    case FACEBOOK_LOGIN_SUCCESS:
    case REGISTER_SUCCESS: {
      const newState = {
        ...state,
        data: action.payload.data,
        error: false,
      }
      return newState
    }

    case UPDATE_PROFILE_PIC_FAILURE:
    case UPDATE_PROFILE_FAILURE:
    case LOGIN_FAILURE:
    case FACEBOOK_LOGIN_FAILURE:
    case REGISTER_FAILURE: {
      const newState = {
        ...state,
        data: action.payload,
        error: true,
      }
      return newState
    }

    case LOGOUT_USER: {
      const newState = {
        ...state,
        data: INITIAL_STATE.data,
        error: INITIAL_STATE.error,
      }
      return newState
    }

    //stay in the same page after failre

    case GET_ME_FAILURE:
    //don't change anything after reset-password action
    case RESET_FAILURE:

    case CHANGE_PASSWORD_SUCCESS:
    case CHANGE_PASSWORD_FAILURE:
    //don't change anything after forgot-password action
    case FORGOT_SUCCESS:
    case FORGOT_FAILURE:

    default: {
      return state
    }
  }
}
