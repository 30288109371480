import api from '../services/api'

export const FORGOT_LOADING = 'FORGOT_LOADING'
export const FORGOT_SUCCESS = 'FORGOT_SUCCESS'
export const FORGOT_FAILURE = 'FORGOT_FAILURE'

export const RESET_LOADING = 'RESET_LOADING'
export const RESET_SUCCESS = 'RESET_SUCCESS'
export const RESET_FAILURE = 'RESET_FAILURE'

export const resetPassword = ({ password, token, callback }) => (dispatch, getState) => {
  dispatch({ type: RESET_LOADING })

  return api({
    method: 'Put',
    url: `/resetPassword`,
    data: {
      password,
      resetPasswordToken: token,
    },
  })
    .then(resp => {
      if ('success' in resp.data && resp.data.success === true) {
        // success
        dispatch({
          type: RESET_SUCCESS,
          payload: {
            data: resp.data,
          },
        })

        getState().user.language === 'en'
          ? callback(resp.data.message, true)
          : callback(resp.data.message_fr, true)
      } else {
        // failed
        dispatch({
          type: RESET_FAILURE,
          payload: {},
        })
        'message' in resp.data &&
          resp.data.success === false &&
          getState().user.language === 'en'
          ? callback(
            resp.data.message.message + ` or token already expired!`, false
          )
          : callback(resp.data.message.message_fr + `ou jeton déjà expiré`, false)
      }
    })
    .catch(err => {
      dispatch({
        type: RESET_FAILURE,
        payload: {},
      })
      console.log('reset password err', err)
    })
}

export const forgotPassword = ({ email, callback }) => (dispatch, getState) => {
  dispatch({ type: FORGOT_LOADING })
  return api({
    method: 'POST',
    url: `/forgot`,
    data: {
      emailAddress: email,
    },
  })
    .then(resp => {
      if ('success' in resp.data && resp.data.success === true) {
        // success
        dispatch({
          type: FORGOT_SUCCESS,
          payload: {
            data: resp.data,
          },
        })
        getState().user.language === 'en'
          ? callback(resp.data.message, true)
          : callback(resp.data.message_fr, true)
      } else {
        // failed
        dispatch({
          type: FORGOT_FAILURE,
          payload: {},
        })

        'message' in resp.data &&
          resp.data.success === false &&
          getState().user.language === 'en'
          ? callback(resp.data.message.message, false)
          : callback(resp.data.message.message_fr, false)
      }
    })
    .catch(err => {
      dispatch({
        type: FORGOT_FAILURE,
      })
      console.log('forgot password err', err)
    })
}
