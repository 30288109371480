import config from '../config'

// fetch
const api = ({
  method,
  url = '',
  data = null,
  tokenExpiredCallback = () => {},
  tokenHeaders = {},
}) => {
  const extraconfig = {}

  // request params
  const requestParams = {
    method,
    cache: 'no-cache',
    mode: 'cors',
    credentials: 'omit',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...extraconfig,
      ...tokenHeaders,
    },
  }

  // post body
  if (data) requestParams.body = JSON.stringify(data)

  return fetch(`${config.api_base_url + url}`, requestParams)
    .catch(err => {
      // error occured
      console.log('error', err)
      throw err
    })
    .then(response => {
      if (
        response.status === 200 &&
        1 == 2 &&
        response.message == 'Failed to authenticate token.'
      ) {
        // log user out
        tokenExpiredCallback()
      }

      return response.json().then(data => ({ data, headers: response.headers }))
    })
}

// let's return back our create method as the default.
export default api
