import { combineReducers } from 'redux'

import loadingReducer from './loading'
import userReducer from './user'
import dealsReducer from './deals'
import alertsReducer from './alerts'
import cashoutReducer from './cashout'
// Combine all the reducers
// Add and manage all the states declare
export default combineReducers({
  loading: loadingReducer,
  user: userReducer,
  deals: dealsReducer,
  alerts: alertsReducer,
  cashout: cashoutReducer,
})
