import api from '../services/api'

export const GET_LATEST_DEALS_LOADING = 'GET_DEALS_LOADING'
export const GET_LATEST_DEALS_SUCCESS = 'GET_DEALS_SUCCESS'
export const GET_LATEST_DEALS_FAILURE = 'GET_DEALS_FAILURE'

export const getLatestDeals = () => (dispatch, getState) => {
  dispatch({ type: GET_LATEST_DEALS_LOADING })

  const token = getState().user.data.token
  return api({
    method: 'GET',
    url: `/offers/getOffers`,
    tokenHeaders: {
      authorization: token,
    },
  })
    .then(resp => {
      if ('success' in resp.data && resp.data.success === true) {
        dispatch({
          type: GET_LATEST_DEALS_SUCCESS,
          payload: {
            data: resp.data.offers,
          },
        })
      } else {
        dispatch({
          type: GET_LATEST_DEALS_FAILURE,
          payload: {},
        })
      }
    })
    .catch(err => {
      // 5xx or 4xx error
      dispatch({
        type: GET_LATEST_DEALS_FAILURE,
        payload: {},
      })
    })
}
