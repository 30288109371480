import { toast } from 'react-toastify'

import api from '../services/api'
import { navigate } from 'gatsby'

export const CASH_OUT_CHEQUE_SUCCESS = 'CASH_OUT_CHEQUE_SUCCESS'
export const CASH_OUT_CHEQUE_FAILURE = 'CASH_OUT_CHEQUE_FAILURE'
export const CASH_OUT_CHEQUE_LOADING = 'CASH_OUT_CHEQUE_LOADING'
export const CASH_OUT_EMAIL_SUCCESS = 'CASH_OUT_EMAIL_SUCCESS'
export const CASH_OUT_EMAIL_FAILURE = 'CASH_OUT_EMAIL_FAILURE'
export const CASH_OUT_EMAIL_LOADING = 'CASH_OUT_EMAIL_LOADING'

//cash out by e-Interac
export const cashOutByEmail = ({ email }) => (dispatch, getState) => {
  dispatch({ type: CASH_OUT_EMAIL_LOADING })

  const token = getState().user.data.token

  return api({
    method: 'POST',
    url: `/receipt/cashOut`,
    tokenHeaders: {
      authorization: token,
    },
    data: {
      postal: false,
      emailAddress: email,
    },
  })
    .then(resp => {
      if ('success' in resp.data && resp.data.success === true) {
        //success cash out
        dispatch({
          type: CASH_OUT_EMAIL_SUCCESS,
          payload: {
            byEmail: true,
            byCheque: false,
            data: resp.data,
          },
        })

        navigate('/app/cashoutsuccess')
      } else {
        //failed
        dispatch({
          type: CASH_OUT_EMAIL_FAILURE,
          payload: {},
        })

        toast.error(
          'message' in resp.data
            ? resp.data.message
            : 'sorry please try cash out later or contact client service'
        )
      }
    })
    .catch(err => {
      dispatch({
        type: CASH_OUT_EMAIL_FAILURE,
        payload: {},
      })

      toast.error(
        'message' in resp.data
          ? resp.data.message
          : 'sorry please try cash out later or contact client service'
      )
    })
}

//cash out by cheque
export const cashOutByCheque = ({ address, city, postalCode, province }) => (
  dispatch,
  getState
) => {
  //loading
  dispatch({ type: CASH_OUT_CHEQUE_LOADING })

  const token = getState().user.data.token
  return api({
    method: 'POST',
    url: `/receipt/cashOut`,
    tokenHeaders: {
      authorization: token,
    },
    data: {
      postal: true,
      address,
      city,
      postalCode,
      province,
    },
  })
    .then(resp => {
      if ('success' in resp.data && resp.data.success === true) {
        //success cash out
        dispatch({
          type: CASH_OUT_CHEQUE_SUCCESS,
          payload: {
            byEmail: false,
            byCheque: true,
            data: resp.data,
          },
        })

        navigate('/app/cashoutsuccess')
      } else {
        //failed
        dispatch({
          type: CASH_OUT_CHEQUE_FAILURE,
          payload: {},
        })

        toast.error(
          'message' in resp.data
            ? resp.data.message
            : 'sorry please try cash out later or contact client service'
        )
      }
    })
    .catch(err => {
      dispatch({
        type: CASH_OUT_CHEQUE_FAILURE,
        payload: {},
      })

      toast.error(
        'message' in resp.data
          ? resp.data.message
          : 'sorry please try cash out later or contact client service'
      )
    })
}
