export default {
  en: {
    termsTitle: 'Terms and Conditions',
    termsSubTitle: '',
    terms: `
<br/>Hello! Welcome and thank you for making Eclipsa your home for finding the best healthy cash back rewards, discounts, and selected deals. At Eclipsa we know how hard you work, and we make sure we do the same thing everyday so you can get more value while buying the products you love. We believe that the best relationships are clearly defined so that everyone knows and what they are getting into, and what they are getting out of it. To that end we have created the terms of use below to set out the nature of our relationship with respect to you using our site and mobile apps:
<br/>These terms of use (the “Terms of Use”) are a legally binding agreement between you (“you” or “your”) and Eclipsa Inc. (“Eclipsa”, “our”, “us” or “we”) and govern your use of the Services (as defined below).
<br/>PLEASE READ THESE TERMS OF USE CAREFULLY. Your use of the Services is expressly conditioned on your acceptance without modification of the following Terms of Use and all policies and guidelines incorporated by reference.
<br/>If you do not agree with them, you are not authorized to use the applicable Services and should discontinue use. Note that all of the Terms of Use below are subject to the laws of the place where you live, and some of them might not be binding on you under those laws.
<br/>
<br/><strong>Introduction</strong>
<br/>References in these terms to the Services includes the following website www.eclipsa.com and all associated web pages and mobile applications, including the Eclipsa mobile application which may be made available on for download and use on any mobile application platform stores (collectively the "Services").
<br/>
<br/>You should read these terms and conditions carefully before using the Services. By accessing or using this Services or otherwise indicating your consent, you agree to be bound by these terms and conditions and the documents referred to in them. If you do not agree with or accept any of these terms, you should cease using the Services immediately. If you have any questions about this website, please click on the link for the Help Centre on our website or mobile apps to access our help section and/or submit questions.
<br/>
<br/><strong>Definitions</strong>
<br/>"Content" means any information, text, images, video, audio or other multimedia content, software or other information or material submitted to, subsisting on or accessible from the Services;
<br/>
<br/>“Eclipsa”, "we", "us" or "our” means Eclipsa Inc., a company registered under the laws of the Province of Ontario and whose registered office is located at 20 Camden St., Suite 200, Toronto, ON M5V 1V1. References to us in these terms also includes our group companies from time to time if any part of the Services is also run by or for the benefit of other group companies, such as subsidiaries.
<br/>
<br/>"you" or "your" means the person accessing or using the Services or its Content.
<br/>
<br/>Privacy Policy and Additional Terms -- These terms include our Privacy Policy which shall be subject to these terms in the event of any conflict or inconsistency and can be found at <a style={{color:"blue"}} href="/privacy">www.eclipsa.com/privacy</a>. These terms may also be supplemented or replaced by additional terms ("Additional Terms") relating to specific content, goods or services made available or supplied by us using the Services. Additional Terms will be made available on relevant pages of the Services and will be accessible by you for your acceptance before you redeem an offer associated with the relevant Eclipsa Deal. Additional Terms shall prevail to the extent that there is any conflict or inconsistency with any other of these terms.
<br/>
<br/>These Services are intended for and directed towards residents of Canada who are, or have reached, the age of majority in their respective Province or Territory.
<br/>
<br/>Accessibility -- We seek to make these Services as accessible as possible. If you have any problems accessing our Services, please click on the link for the Help Centre on our website or mobile apps to access our help section and/or submit questions.
<br/>
<br/><strong>Cash Back Rewards Program</strong>
<br/>By using the Services, you may be eligible to earn rewards (the “Cash Back Reward(s)”) by watching advertisements, purchasing products or services, or otherwise as provided by the Cash Back Rewards Program (the “Eclipsa Deal(s)”). The Cash Back Rewards earned will be based on the associated value, as determined by Eclipsa in its sole discretion, attributed to the Eclipsa Deal.
<br/>
<br/>To earn Cash Back Rewards from watching advertisements, you may be required to complete an associated questionnaire and/or survey and submit it to us during the time the Eclipsa Deal is valid. To earn Cash Back Rewards from purchasing products and services you must purchase the product or service, and submit a photo of your receipt through our mobile app to verify that you have in fact made the purchase.
<br/>
<br/>Photos of receipts must be submitted through our mobile application, must be of high quality, legible, and provide sufficient information to validate your compliance with the Eclipsa Deal (such as a description of the item purchased and date of purchase). Products that are purchased in connection with an Eclipsa Deal and submitted for Cash Back Rewards may not be returned. In addition to the above, each Eclipsa Deal describes the restrictions, limitations or additional obligations that apply to that Eclipsa Deal.
<br/>
<br/>Eclipsa Deals are subject to these Terms of Use and the guidelines found at www.eclipsa.com/terms. All decisions to award Cash Back Rewards, including without limitation any determination as to whether you have met the restrictions, limitations and requirements of an Eclipsa Deal, or whether appropriate documentation has been provided to validate compliance with the requirements of the Eclipsa Deal, will be made by Eclipsa, at its sole discretion. All such decisions will be final and binding and not subject to appeal.
<br/>
<br/><strong>Redemption of Cash Back Rewards</strong>
<br/>Once your Cash Back Rewards reach a balance of $20, you are eligible to redeem your Cash Back Rewards for an equal amount in Canadian currency. To redeem your Cash Back Rewards, you must deliver a request (the “Request”) to us by selecting the “Cash Out” option on the Mobile App and/or our Website, and responding to the questions required for delivery of your Cash Back Rewards.
<br/>
<br/>You are responsible for redeeming your Cash Back Rewards. If we do not receive a Request from you, we are under no obligation to provide Cash Back Rewards. We do not provide for an automatic redemption or periodic distribution of Cash Back Rewards.
<br/>
<br/>Cash Back Rewards have no cash, monetary or other value. Your Cash Back Rewards or any part thereof are not transferable, cannot be divided as part of a settlement, legal proceeding and/or death. No interest is paid on outstanding balances of Cash Back Rewards.
<br/>
<br/>If you return any products for which you have accumulated Cash Back Rewards we reserve the right to deduct from your Cash Back Rewards the value of the offer related to such returned products and/or terminated your accounts.
<br/>
<br/>For online orders you must upload or email a photo of an itemized packing slip to verify that you have completed the purchase and received the items purchased.
<br/>
<br/>The final decision on the legibility and/or sufficiency of information you provide to us through any receipt or proof of purchase related to any Cash Back Reward and/or Eclipsa Deal is at our sole discretion in accordance with commercially reasonable standards.
<br/>
<br/>Photos or scans taken through the app and/or which are emailed to Eclipsa must be of the original paper receipt which was issued by a retailer which cannot have been altered in any way, and must be the receipt from a purchase that you paid for personally.
<br/>
<br/><strong>Payment of Cash Back Rewards</strong>
<br/>All Cash Back Rewards will be paid by Eclipsa by Interac e-Transfer, cheque, or otherwise as determined from time to time by Eclipsa. All Interac e-transfers will be sent to the email address and/or phone number designated in your Request and account information and any information you require to receive such Cash Back Rewards such as an Interac e-Transfer password will be provided to you in a manner that is at the sole discretion of Eclipsa and which may change from time to time. It is your obligation to keep your Interac e-Transfer password secure for all Cash Back Rewards redemptions. We are not responsible in any manner, either directly or indirectly, for any delays and/or losses in any and all Interac e-Transfers which may be sent to you for redemption of your Cash Back Rewards which are caused by Interac and/or any relevant financial services provider. All cheques will be sent to you via regular mail from our place of business, unless otherwise determined by Eclipsa. We are not responsible for any lost or delayed cheques, whether such loss or delay was caused by Eclipsa, the postal service or any other third party. You must redeem your Cash Back Rewards and accept your payment, whether through cheque and/or Interac e-Transfer, in the time afforded to you to redeem your Cash Back Reward based on the method of redemption respectively. For greater certainty, for Cash Back Rewards claimed through cheque, it shall be 6 months from the date of issue, and for Cash Back Rewards claimed by Interac e-Transfer it shall be the time set out by Interac and/or your financial institution on the transfer sent to you.
<br/>
<br/>Cheques for the Cash Back Rewards will be sent to the mailing address provided during the Eclipsa cash out process. If you did not provide a mailing address we may, but are under no obligation to, request that you provide a mailing address for delivery of the cheque. If we do not receive a valid mailing address for you within 30 days of the Request, you may forfeit your Cash Back Rewards. We are under no obligation to provide a replacement cheque if your Cash Back Rewards cheque is lost, stolen or expires before you cash it, however we may at our sole discretion, add the amount of your Cash Back Rewards cheque less a fee of $10 (or such other amount as permitted by applicable law) to your Cash Back Rewards balance.
<br/>
<br/>You are responsible for all taxes, if any, associated with the receipt and payment of the Cash Back Rewards.
<br/>
<br/>
<br/><strong>Restrictions on Use and Prohibited Activity</strong>
<br/>Provided you comply at all times with these Terms of Use, we grant to you during the duration hereof, a limited, revocable, non-exclusive, non-transferable and non-sub-licensable right to use the Services solely for your personal and non-commercial use. The foregoing license is personal to you and may not be transferred or assigned. By using the Services, you represent and warrant that:
<br/>all account registration information you submit to Eclipsa is truthful, accurate and complete and you will maintain the accuracy and completeness of such information throughout the duration of your use of the Services;
<br/>You will only create and hold one account and only for your personal use. You may not associate more than one user account with any mobile device;
<br/>Your responses to all questionnaires are truthful and accurate;
<br/>You are using the Services for yourself personally and not on behalf of any third party;
<br/>You are a resident of Canada, have reached the age of majority in your respective Province or Territory, and you can form legally binding contracts under applicable law;
<br/>Your use of the Services does not violate any applicable law. It is solely your responsibility to determine whether your use of the Services complies with applicable laws, and you must comply with all such applicable laws.
<br/>not to defame or disparage anybody or make comments of an obscene, derogatory or offensive manner or otherwise use the Services or its Content in a way that brings us or any third party into disrepute or causes us to be liable to any third party;
<br/>not to reverse engineer, decompile, copy, modify, distribute, transmit, license, sublicense, display, revise, perform, transfer, sell or otherwise make available to any third party or otherwise publish, deep link, create derivative works from or exploit in any way the Services or its Content except as permitted by us under these terms or as expressly provided under applicable law and/or under any Additional Terms;
<br/>not to use the Services to distribute viruses or malware or other similar harmful software code;
<br/>not to represent or suggest that we endorse any other business, product or service unless we have separately agreed to do so in writing;
<br/>not to sell, reproduce, modify or attempt to modify the Services or any Content in any way;
<br/>not to use the Services (or any part thereof) for any public or commercial purpose, including without limitation use of any Content on any other website or mobile application;
<br/>not to reverse engineer, de-compile, disassemble or otherwise attempt to discover the source code or underlying algorithms of the Services;
<br/>not to modify, translate, or create derivative works based on any portion of
<br/>the Services or any Content;
<br/>not to access or use the Services in order to build a competitive product or service or copy any features, functions or graphics of the Services;
<br/>not to access or use the Services for purposes of monitoring its availability, performance or functionality, or for any other benchmarking or competitive purposes;
<br/>not to circumvent or modify, attempt to circumvent or modify, or encourage or assist any other person in circumventing or modifying any security technology or software that is part of the Services;
<br/>not to knowingly access data on or available through the Services not intended for you;
<br/>not to attempt to probe, scan or test the vulnerability of any portion of the Services or to breach the security or authentication measures without proper authorization;
<br/>not to impersonate or attempt to impersonate Eclipsa or our employees (including, without limitation, the use of e-mail addresses associated with any of the foregoing);
<br/>not to use the account, username, or password of another user of the Services at any time;
<br/>you are solely responsible for all costs and expenses you may incur in relation to your use of the Services and shall be solely responsible for keeping your password and other account details confidential;
<br/>you will not submit to Eclipsa purchase information that is false, inaccurate, fabricated, counterfeited, tampered with, adjusted, or otherwise artificial or inauthentic for the purpose of seeking to redeem an Eclipsa Deal;
<br/>you will not redeem, or attempt to redeem, an Eclipsa Deal for products that have not actually been purchased and retained, or for products that have been purchased but returned to a store or online vendor, following purchase;
<br/>you will not upload receipts to Eclipsa that have already been uploaded by you or another user, for the purpose of earning credit from Eclipsa for purchasing products that you did not actually purchase;
<br/>you will not upload receipts to Eclipsa that reflect purchases that took place more than one (1) week prior to the date on which the receipt is uploaded;
<br/>you will not upload receipts to Eclipsa that reflect purchases that took place prior to the launch date of an Eclipsa Deal except where expressly permitted in the Eclipsa Deal details;
<br/>you will not attempt to obscure the date on a receipt, such as by purposefully uploading a receipt without any date shown or without any clearly legible date shown;
<br/>We reserve the right to prevent or suspend your access to the Services if you do not comply with any part of these terms and conditions or any applicable law.
<br/>
<br/>Eclipsa reserves the right to modify, suspend or terminate the Eclipsa Deals program or the Services (or any part thereof), either temporarily or permanently, at any time or from time to time, with or without prior notice or liability to you. Without limiting the foregoing, Eclipsa may terminate the Eclipsa Deals program in its sole discretion, which may result in the forfeiting of any Cash Back Rewards accumulated pursuant to these Terms of Use. Eclipsa reserves the right to modify or replace any terms and conditions of contained in these Terms of Use or any of its policies, practices and guidelines related to the Eclipsa Deals program or the Services. We reserve the right in our sole and absolute discretion to make changes from time to time and without notice in how we offer and operate the Services. You agree that Eclipsa shall not be liable to you for any modification or cessation of the Eclipsa Deal program or the Services. You acknowledge that Eclipsa has no express or implied obligation to provide, or continue to provide, the Eclipsa Deals progam or the Services, or any part thereof, now or in the future. To the extent that any of the foregoing requires a modification of these Terms of Use, such modification will be made in accordance with Section 14.
<br/>
<br/><strong>Account Login and Inactivity</strong>
<br/>Use of the Services may only be accessed by setting up an account with a login name and password. You are solely responsible and liable for any use and misuse of your login name and password and for all activities that occur under your login name and password. You may not transfer or otherwise assign your account to any third party. For security reasons, you must keep your login name and password confidential and not disclose them to any person or permit any other person to use them, except an authorized representative of Eclipsa. Eclipsa recommends that you choose a password that is unique to you and not easily guessed by others. You should change your password on a regular basis, and you must log out at the end of each session.
<br/>
<br/>You must immediately notify Eclipsa of any unauthorized use of your login name or password, or if you know or suspect that your login name or password has been lost or stolen, has become known to any other person, or has been otherwise compromised. All login names and passwords remain the property of Eclipsa. Eclipsa is not under any obligation to verify the actual identity or authority of the user of any login name or password.
<br/>
<br/>If you do not validly claim an Eclipsa Deal during any twelve-month period, we will deem your account as inactive. If your account is deemed inactive, we have the right without any further notice to you, to apply an inactive account management fee in the amount equal to $2 of Cash Back Rewards per month. The inactive account management fee will be applied against your current Cash Back Rewards balance, provided that your Cash Back Rewards balance will not become a negative amount. Once you claim an Eclipsa Deal under your account, your account will become active, and no further account management fees will be applied unless and until your account is deemed inactive again, as described above.
<br/>
<br/>
<br/><strong>Mobile</strong>
<br/>Eclipsa offers mobile applications as a fast and easy way for our users to access our Services. Our mobile applications may include the ability to access the Services and upload content to the Services, receive content from the Services and download applications to your wireless device (collectively, "Mobile Features"). Standard messaging, data, and other fees may be charged by your carrier to participate in the Mobile Features. Fees and charges may appear on your wireless bill or be deducted from your pre-paid balance. Your carrier may prohibit or restrict certain Mobile Features and certain Mobile Features may be incompatible with your carrier or device. You should check with your carrier to find out what plans are available and how much they cost or any other questions regarding these carrier-related issues. By using the Mobile Features, you agree that we may communicate with you regarding the Services by text message or other electronic means to your mobile device.
<br/>
<br/><strong>Ownership, Use, and Intellectual Property Rights</strong>
<br/>These Services and all intellectual property rights in the Services (including without limitation any Content) are owned by us and/or our licensors. We and our licensors reserve all our intellectual property rights (which include without limitation all copyright, trade-marks, domain names, design rights, database rights, patents and all other intellectual property rights of any kind) whether registered or unregistered anywhere in the world.
<br/>
<br/>Nothing in these terms and conditions grants you any rights in the Services other than as necessary to enable you to access the Services. You agree not to adjust and/or try to circumvent or delete any intellectual property notices contained on the Services and in particular in any digital rights or other security technology embedded or contained within any Services Content.
<br/>
<br/>Trade-Marks – “Eclipsa” and any associated word and/or logo marks are our trade-marks, and the trade-marks on our website and mobile applications of our various partner companies who may offer products, services, and/or events to participate in our Eclipsa Deals program are trademarks of those companies. Other trade-marks and trade names may also be used on this Services. The use or misuse of any trade-marks or any other Content on the Services except as provided in these terms and conditions is strictly prohibited. Nothing contained on the Services shall be construed as granting, by implication, estoppel or otherwise, any licence or right to use any trade-mark without our prior written permission and/or the permission of our partner companies.
<br/>
<br/>Feedback -  Eclipsa may, at its sole discretion, enable its users to provide Eclipsa with feedback by email or otherwise (“User Content”). If you provide User Content you grant Eclipsa a non-exclusive, royalty-free, perpetual, irrevocable, and fully sub-licensable right to use, delete, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, and display that User Content throughout the world in any media, now known or later developed, for any purpose whatsoever, commercial or otherwise, without providing compensation to you or any other person, without any liability whatsoever, and free from any obligation of confidence, and you waive any and all moral rights in the User Content. You also grant Eclipsa the right to use the name you submit with the User Content, if any, in connection with Eclipsa’s rights set out in this section.
<br/>
<br/><strong>Software</strong>
<br/>Where any software is made available for downloading from the Services, this is our copyrighted work and/or that of our licensors. You may only use such software in accordance with the terms of the end user licence agreement, if any, which accompanies the software and, subject thereto, in accordance with these terms. All such software is made available for downloading solely for your personal use in a non-commercial or public manner. Any reproduction or redistribution of software not in accordance with the end user licence agreement and/or these terms is expressly prohibited, and may result in severe civil and criminal penalties. Our software is war-ranted only to the extent expressly required by Canadian law or according to the terms of the end user licence agreement. We hereby disclaim all other warranties, conditions and other terms (whether express or implied) with regard to the software, including all implied warranties and conditions of satisfactory quality, freedom from defects and fitness for a particular purpose. In no event shall our aggregate liability in respect of any such software exceed the limitations of liability in the applicable end user licence agreement, or in all other cases $100 in the aggregate.
<br/>
<br/>Accuracy of Information and Availability of the Services
<br/>While we use reasonable efforts to include accurate and up-to-date information on the Services, we do not represent, warrant or promise (whether expressly or by implication) that any Content is or remains available, accurate, complete and up to date, free from bugs, errors or omissions or fit or suitable for any purpose. Any reliance you may place on the information on these Services is at your own risk and we may suspend or terminate operation of the Services at any time at our sole discretion. Nothing in these terms and conditions shall operate to prejudice any mandatory statutory requirement or your statutory rights. Content on the Services is provided for your general information purposes only and to inform you about us and our products and news, features, services and other websites, which may be of interest. It does not constitute technical, financial or legal advice or any other type of advice and should not be relied on for any purpose.
<br/>
<br/>While we make commercially reasonable efforts to ensure that the Services are available, we do not represent, warrant or guarantee in any way the Services’ continued availability at all times or uninterrupted use by you of the Services.
<br/>
<br/><strong>Hyperlinks and Third Party Sites</strong>
<br/>The Services may contain hyperlinks or references to third party websites other than the Services. Any such hyperlinks or references are provided for your convenience only. We have no control over third party websites and accept no responsibility for any content, material or information contained in them. The display of any hyperlink and reference to any third party website does not constitute an endorsement of such third party's website, products or services. Your use of a third party site may be governed by the terms and conditions of that third party site.
<br/>
<br/><strong>Warranties and Limitation of Liability</strong>
<br/>You agree that your use of the Services is on an "as available" basis. As stated above, except as otherwise expressly required by applicable law, we make no representations, warranties, conditions or other terms (whether express or implied) in relation to the provision of the Services, including without limitation as to completeness, accuracy and currency or any Content on the Services, or as to satisfactory quality or fitness for a particular purpose.
<br/>
<br/>To the maximum extent permitted by applicable law, we exclude all liability (whether arising in contract, tort, breach of statutory duty or otherwise) which we may otherwise have to you as a result of any error or inaccuracies in any Content, the unavailability of the Services for any reason, and any representation or statement made on the Services.
<br/>
<br/>We will not be liable for any loss or damage we cause which we could not reasonably anticipate when you started using the Services, for example if you lose revenue, salary, profits or reputation as a result of your use of the Services and/or the acts or omissions of any third party such as other users of the Services or any other indirect or consequential loss or damage you may incur in relation to the Services and its Content.
<br/>
<br/>Under no circumstances shall our aggregate liability to you for any and all claims arising from your use of the Services (including the downloading or use of any Content) exceed $100.
<br/>
<br/>In no event will Eclipsa, and/or its various affiliates, licensors, partner companies, or other relevant third parties involved in facilitating the Services be liable for any injury, loss, claim, damage, or any special, exemplary, punitive, direct, indirect, incidental or consequential damages of any kind (including, but not limited to economic loss, lost profits or lost savings), whether based in contract, tort, strict liability, or otherwise, which arises out of or is in any way connected with any use of the Services or any failure or delay in the operation of the Services, even if advised of the possibility of damages.
<br/>
<br/>Any exclusions and limitations of liability in these terms shall be subject to the Additional Terms in respect of matters covered by those Additional Terms and as otherwise required by law.
<br/>
<br/><strong>Term and Termination</strong>
<br/>The term of these Terms of Use commences upon acceptance by you and continues until terminated in accordance with these Terms of Use.
<br/>
<br/>You agree that Eclipsa, in its sole discretion, may terminate or suspend these Terms of Use, and/or your account or any part thereof, including your use of the Services, for any reason, including, without limitation, (a) your account is deemed inactive for a period of 12 months or more, (b) if Eclipsa believes that you have violated or acted inconsistently with these Terms of Use, (c) requests by law enforcement or other government agencies, (d) a request by you (self-initiated account deletions), (e) discontinuance or material modification to the Services (or any part thereof), (f) unexpected technical or
<br/>security issues or problems, (g) in compliance with legal process; or (h) if you have or we believe you have engaged in illegal activities, including without limitation, fraud. Eclipsa may also, in its sole discretion and at any time, discontinue providing the Services, or any part thereof, with or without notice. You acknowledge and agree that any termination of your access to the Services under any provision of this Terms of Use may be effected without prior notice. Further, you agree that Eclipsa is not liable to you for termination of your account, or termination of your access to the Services, except as expressly provided herein.
<br/>
<br/>Upon termination of your account, or termination of your access to the Services, any then outstanding Eclipsa Deal may be terminated prior to its stated end time. If your account is terminated for violation of the Terms of Use, your entire Cash Back Rewards balance will be cancelled and voided. If your account is terminated at your request, it will be your responsibility to make a Request (subject to our minimum $20 Cash Back Rewards balance requirement described above) before requesting such cancelling. If your account is terminated other than for violation of the Terms of Use or at your request, and you have a Cash Back Rewards balance of at least $20, we will use commercially reasonable efforts to give you notice of the termination and to provide at least 30 days from the date of the notice to make a Request to redeem your Cash Back Rewards balance in accordance with these Terms of Use. If you do not elect to make a Request within the time period provided, you will forfeit your entire Cash Back Rewards balance and any entitlement to make a Request.
<br/>
<br/>All Terms of Use set forth herein that should by their nature survive termination (including without limitation all provisions relating to intellectual property, ownership, and indemnification) in order to be given full effect shall continue in full force and effect after any expiration or termination of the Terms of Use.
<br/>
<br/><strong>General</strong>
<br/>These terms are dated [date on which terms were last updated]. No changes to these terms are valid or have any effect unless agreed by us in writing. We reserve the right to vary these terms and conditions from time to time. Our new terms will be displayed on the Services and by continuing to use and access the Services following such changes, you agree to be bound by any variation made by us. It is your responsibility to check these terms and conditions from time to time to verify such variations.
<br/>
<br/>If you do not agree with the revised Terms of Use, you may cancel your account, and make a Request to redeem your outstanding Cash Back Rewards balance provided you have a Cash Back Rewards balance of at least $20. Your continued use of the Services signifies your acceptance of any revised Terms of Use. You should check back frequently to reach the most recent version.
<br/>
<br/>Unless otherwise expressly stated in these terms and conditions, all notices from you to us must be submitted through our Help Centre contact ticket submission system on our website and/or mobile app. All notices from us to you will be displayed on our website and/or mobile applications from time to time.
<br/>
<br/>We shall have no liability to you for any breach of these terms caused by any event or circumstance beyond our reasonable control including, without limitation, strikes, lock-outs and other industrial disputes, breakdown of systems or network access, flood, fire, explosion or accident.
<br/>
<br/>If any part of these terms and conditions is unenforceable (including any provision in which we exclude or limit our liability to you) the enforceability of any other part of these terms and conditions will not be affected. If we choose not to enforce any right that we have against you at any time, then this does not prevent us from later deciding to exercise or enforce that right.
<br/>
<br/>These terms and conditions (together with the Privacy Policy and any applicable Additional Terms or other terms expressly referred to in these terms and incorporated by reference contain the entire understanding and agreement between us and you in relation to your use of the Services and supersede and replace any representation, statement or other communication (whether written or otherwise) made by you or us which is not contained herein. Save for fraud or fraudulent misrepresentation, we shall have no liability for any such representation being untrue or misleading.
<br/>
<br/>You may not assign, sublicense or otherwise transfer any of your rights and obligations in these terms to any other person.
<br/>
<br/>These terms and conditions shall be construed in accordance with and governed by the laws in effect within Ontario, Canada.
<br/>
<br/>
<br/><strong>Questions or Comments</strong>
<br/>If you have any questions or comments about these terms or anything else related to the Services or Eclipsa Deals, please click on the link for the Help Centre or send us an email at: <a href="mailto:help@eclipsa.com">help@eclipsa.com</a>
<br/>
<br/> Last modified: April 1, 2020
</br>`,
  },
  fr: {
    termsTitle: 'Conditions d’Utilisation',
    termsSubTitle: '',
    terms: `</br>Bienvenue ! Merci d’avoir choisi Eclipsa pour accéder à des promotions remises et offres exceptionnelles. Chez Eclipsa, nous croyons que la meilleure façon d’établir une relation durable et transparente est de vous informer de ce dont vous pouvez bénéficier, et la façon d’y mettre un terme. Ainsi, nous avons créé des conditions d’utilisation afin de donner un cadre à notre relation pour l’utilisation de notre site et de nos applications mobiles :
</br>
</br>Ces conditions d’utilisations (les « Conditions d’Utilisation » ou « Conditions ») forment un contrat entre vous (« vous ») et Eclipsa Inc. (« Eclipsa », « nôtre », « nous ») et gouvernent l’utilisation de nos Services (tels que définis ci-dessous).
</br>
</br>S’IL VOUS PLAÎT LISEZ CES CONDITIONS AVEC ATTENTION. Votre utilisation des Services est conditionnée par l’acceptation sans modification possible des présentes Conditions d’Utilisation et de toute politiques et règles directrices incorporées par références.
</br>
</br>Si vous êtes en désaccord avec ces Conditions, vous ne pourrez utiliser et devrez cesser toute utilisation des Services. Notez que les Conditions d’Utilisation ci-dessous sont soumises à la loi de votre pays de résidence, et que certaines provisions peuvent ne pas avoir d’effet juridique dans le pays où vous résidez.
</br>
</br><strong>Introduction</strong>
</br>Les Services concernés par ces Conditions incluent le site www.eclipsa.com et toutes les pages et les applications mobiles associées, dont l’application mobile Eclipsa qui peut être téléchargée et utilisée à partir de toutes les plateformes d’achats d’applications (collectivement dénommés les « Services »).
</br>
</br>Vous devez lire ces Conditions avec attention avant d’utiliser les Services. En accédant ou utilisant les Services, vous acceptez être liés par ces conditions d’utilisation et les documents qui y sont mentionnés. Si vous êtes en désaccord ou n’acceptez pas quelconque provision de ces Conditions, vous devez immédiatement cesser d’utiliser les Services. Si vous avez une question à propos de ce site, cliquez sur le lien du Centre d’assistance sur notre site ou sur nos applications mobiles pour accéder à la section Besoin d’aide et/ou soumettre des questions.
</br>
</br><strong>Définitions</strong>
</br>
</br>« Contenu » signifie tout texte, information, images, vidéos, audio ou autres contenus multimédias, logiciels ou toute autre information soumis, présent ou accessible depuis nos Services ;
</br>
</br>« Eclipsa », « nous », « nôtre » signifie Eclipsa Inc., une société soumise aux lois de la Province d’Ontario, Canada, et dont le siège social se situe au 20 Camden St., Suite 200, Toronto, ON M5V 1V1. Les références à Eclipsa Inc. dans les présentes Conditions incluent également les autres sociétés de notre groupe dans le cas où, de temps en temps, toute ou partie des Services est également gérée par ou pour le bénéfice d’une société du groupe, telle qu’une filiale.
</br>
</br>« Vous » signifie la personne qui accède et utilise les Services ou son Contenu.
</br>
</br>Politique de Confidentialité et Conditions Additionnelles – Ces Conditions incluent notre Politique de Confidentialité qui est soumise à ces Conditions en cas de conflit d’interprétation ou de disparités entre les deux textes. Notre Politique de Confidentialité est accessible à www.eclipsa.com/privacy. Ces Conditions peuvent aussi être complétés ou remplacés par des conditions additionnelles (« Conditions Additionnelles ») à propos de contenu, produits ou services spécifiques proposés ou fournis dans le cadre de l’utilisation de ces Services. Les Conditions Additionnelles seront accessibles sur les pages appropriées des Services et seront soumis à votre approbation avant le remboursement d’une offre associée à l’offre Eclipsa appropriée. Les Conditions Additionnelles doivent prévaloir dans la mesure où elles ne sont pas en conflit ou en contradiction avec les présentes Conditions.
</br>
</br>Ces Services sont destinés aux résidents du Canada qui ont atteint l’âge de la majorité de leur Province ou Territoire respectif.
</br>
</br>Accessibilité – Nous cherchons à rendre nos Services le plus accessible possible. Si vous rencontrez un problème lors de l’utilisation des Services, cliquez sur le lien du [Centre d’assistance] sur notre site ou sur nos applications mobiles pour accéder à la section Besoin d’aide et/ou soumettre des questions.
</br>
</br><strong>Programme de remises </strong>
</br>En utilisant les Services, vous pouvez être éligible pour remporter des remises d’argent (« Remises ») en regardant des publicités, achetant des produits ou services ou autres actions telles que prévues dans le programme de remises (le(s) « Offre(s) Eclipsa »). Les remises gagnées seront basées sur leur valeur associée à l’Offre Eclipsa correspondante telle que déterminée discrétionnairement par Eclipsa.
</br>
</br>Pour gagner des Remises à partir du visionnage de publicités, vous devrez compléter un questionnaire et/ou un sondage et nous le soumettre pendant la période de validité de l’Offre Eclipsa. Pour gagner des Remises à partir de l’achat de produits et services, vous devrez acheter le produits ou service, puis soumettre une photo du reçu d’achat via votre application mobile afin de vérifier que l’achat a réellement été effectué.
</br>
</br>Les photos des reçus d’achat doivent être soumis via l’application mobile et doivent être de haute qualité, lisibles, et doivent contenir suffisamment d’information pour valider les conditions de l’Offre Eclipsa correspondante (tels que la description de l’article acheté et la date d’achat). Les produits achetés en relation à une Offre Eclipsa et soumis au programme de Remises ne peuvent être retournés ni remboursés. De plus, chaque Offre Eclipsa décrit les restrictions, contraintes et limitations et toute obligation additionnelle pouvant s’appliquer à l’Offre Eclipsa.
</br>
</br>Les Offres Eclipsa sont soumises à ces Conditions d’Utilisation et aux règles directrices accessibles sur www.eclipsa.com/terms. Toutes décisions de remise de Remises, y compris, sans limitation, toute décision liée à la satisfaction des conditions, aux restrictions et limitations de l’Offre Eclipsa, ou si la documentation appropriée a été fournie pour valider la satisfaction des conditions de l’Offre Eclipsa, seront prises de façon discrétionnaire par Eclipsa. Toutes ces décisions seront définitives et ne pourront faire l’objet d’une contestation ou d’un appel.
</br>
</br><strong>Remboursement des Remises</strong>
</br>Une fois que votre seuil de Remises a atteint 20 $, vous êtes éligible au remboursement de vos Remises pour un montant similaires en dollars canadiens. Pour obtenir le remboursement de vos Remises, vous devez soumettre une demande (la « Demande ») en sélectionnant l’option « encaisser ».
</br>
</br>Vous êtes responsables du remboursement de vos Remises. Si nous ne recevons pas de Demande de votre part, nous n’avons aucune obligation de vous fournir les Remises. Nous ne fournissons pas de remboursement automatique ou périodique des Remises.
</br>
</br>Les Remises n’ont aucune valeur monétaire ou une quelconque autre valeur. Votre Remise n’est pas transférable ni cessible et ne peut être divisée dans le cadre d’un accord ou d’un règlement juridique, de poursuites judiciaires et/ou d’un décès. Aucun intérêt ne peut être payé à propos des soldes impayés.
</br>
</br>Si vous retournez un produit pour lequel vous avez accumulé une/des Remise(s), nous nous réservons le droit de déduire la valeur de l’offre associée du montant de la/les Remise(s) correspondant au produit(s) retourné(s) et/ou de supprimer votre compte.
</br>
</br>Pour les commandes en lignes, vous devez mettre en ligne ou envoyer par courriel le bordereau d'expédition détaillé pour vérifier que vous avez effectivement complété l’achat et reçu l’article commandé.
</br>
</br>La décision finale de la lisibilité ou de la suffisance des informations que vous nous fournissez via les reçus ou les preuves d’achats liés à toute Remise et/ou Offre Eclipsa est de notre seul ressort et de notre seule discrétion en accord avec des standards commerciaux raisonnables.
</br>
</br>Les photos ou scans pris via l’application et/ou qui sont envoyés par courriel à Eclipsa doivent concerner les reçus papiers originaux qui ont été émis par le vendeur et ne peuvent pas être altérés ou modifiés d’aucune façon, et doivent être des reçus pour des achats que vous avez personnellement effectués.
</br>
</br><strong> des Remises</strong>
</br>Toutes les Remises seront payées par Eclipsa par Virement Interac, chèque ou autre moyen de paiement déterminé de temps en temps par Eclipsa. Tous les transferts par Virement Interac seront envoyés à l’adresse courriel et/ou au numéro de téléphone spécifiés dans votre Demande et dans les informations de votre compte. Toutes les informations dont vous avez besoin pour recevoir les Remises (telles que les mots de passe pour le Virement Interac) vous seront fournis à la discrétion d’Eclipsa et peuvent changer de temps en temps. Vous avez l’obligation de garder votre mot de passe pour le Virement Interac en sécurité pour tout remboursement de Remises. Nous ne pourrons être tenus responsables d’aucune manière, de façon directe ou indirecte, pour tout retard et/ou perte relatifs à tout transfert via Virement Interac envoyé pour le remboursement des Remises et causés par Interac et/ou tout autre fournisseur de service de paiement. Tous les chèques vous seront envoyés par courriel de notre établissement d’affaires, sauf si nous en déterminons autrement. Nous ne pouvons être tenus responsables de toute perte ou retard des chèques, que la perte ou le délai soit causé par Eclipsa, le service postal ou toute autre tierce partie. Vous devez rembourser votre Remise et accepter le paiement, que ce soit par chèque et/ou Virement Interac, dans la période de temps déterminées pour le remboursement de la Remise sur la base de la méthode de remboursement associée à la Remise. Les remboursements de Remises réclamés par chèque seront effectués dans les 6 mois de la date d’émission. Les remboursements de Remises réclamés par Virement Interac seront effectués à la date fixée par Interac et/ou le fournisseur de service de paiement pour l’envoi du transfert.
</br>
</br>Les chèques pour le remboursement des Remises seront envoyés à l’adresse postale fournie lors de l’encaissement de l’Offre Eclipsa. Si vous n’avez pas fourni d’adresse postale, nous pourront, sans toutefois en être obligés, vous demander de fournir une adresse postale valide pour vous délivrer le chèque. Si nous ne recevons pas d’adresse postale valide dans les 30 jours de la Demande, vous êtes susceptibles de perdre votre Remise. Nous ne sommes soumis à aucune obligation de fournir un chèque de remplacement si votre chèque de Remise est perdu, volé ou parvenu à expiration avant l’encaissement. Cependant, nous pouvons, à notre seule discrétion, ajouter le montant du chèque de Remise diminué de 10 $ de frais (ou tout autre montant permis par la loi applicable) à votre balance de Remises.
</br>
</br>Vous êtes responsables de tous impôts ou taxes associés au reçu et/ou au paiement des Remises, dont vous pouvez être redevables.
</br>
</br><strong>Restrictions d’Utilisation et Activités Prohibées</strong>
</br>A condition que vous respectez ces Conditions d’Utilisation à tout moment, nous vous accordons pendant toute la durée effective de ces Conditions, une licence limitée, révocable, non-exclusive, non-cessible, non-transférable du droit d’utiliser les Services pour votre utilisation personnelle et non-commerciale uniquement. Cette licence est individuelle et vous concerne personnellement, elle ne peut être cédée ou transférée d’aucune manière. En utilisant les Services, vous représentez et garantissez que :
</br>toutes les informations liées à votre compte que vous transmettez à Eclipsa sont véridiques, exactes et complètes et vous vous engagez à maintenir l’exactitude et l’exhaustivité de ces informations tout au long de votre utilisation des Services ;
</br>vous ne créerez et détiendrez qu'un seul compte et uniquement pour votre usage personnel. Vous ne pouvez pas associer plus d'un compte d'utilisateur par téléphone intelligent.
</br>toutes vos réponses aux questionnaires sont véridiques et adéquates ;
</br>vous utilisez les services pour votre utilisation personnelle et non pour le compte d'un tiers ;
</br>vous résidez au Canada, vous avez atteint l'âge de la majorité dans votre province ou votre territoire et vous pouvez conclure des contrats juridiquement contraignants en vertu de la loi applicable ;
</br>votre utilisation des Services n’enfreint aucune loi applicable. Il vous appartient de déterminer si votre utilisation des Services est conforme aux lois applicables et vous devez vous conformer à toutes les lois applicables ;
</br>ne pas diffamer ou dénigrer qui que ce soit, ni faire de commentaires obscènes, péjoratifs ou choquants, ni utiliser les Services ou son contenu d'une manière qui nous discréditerait ou engagerait notre responsabilité vis-à-vis de tiers ;
</br>ne pas décompiler, copier, modifier, distribuer, transmettre, licencier, sous-licencier, afficher, réviser, exécuter, transférer, vendre ou autrement mettre à la disposition de tiers ou publier, relier, créer des travaux dérivés à partir de ou d’exploiter de quelque manière que ce soit, les services ou leur contenu, sauf si nous le permettons conformément aux présentes conditions ou si cela est expressément prévu par la loi en vigueur et/ou en vertu de Conditions Additionnelles ;
</br>ne pas utiliser les Services pour diffuser des virus, des logiciels malveillants ou tout autre codes ou logiciels nuisibles ;
</br>ne pas présenter ou suggérer que nous soutenons ou supportons toute autre société, produit ou service à moins que nous ayons convenu séparément de le faire par écrit ;
</br>ne pas vendre, reproduire, modifier ou tenter de modifier les Services ou tout Contenu de quelque manière que ce soit ;
</br>ne pas utiliser les Services (ou une quelconque partie des Services) à des fins publiques ou commerciales, y compris, sans limitation, l'utilisation de tout Contenu sur tout autre site web ou application mobile ;
</br>ne pas désosser, décompiler, désassembler ou tenter de révéler ou découvrir le code source ou les algorithmes sous-jacents des Services ;
</br>ne pas modifier, traduire ou créer des œuvres dérivées basées sur toute partie des Services ou de tout Contenu ;
</br>ne pas accéder ou utiliser les Services afin de créer un produit ou un service concurrent ou de copier des caractéristiques, fonctions ou éléments graphiques des Services ;
</br>ne pas accéder ou utiliser les Services pour contrôler de leur disponibilité, leurs performances ou leurs fonctionnalités, ou toute autre analyse comparative, ou à des fins concurrentielles ;
</br>ne pas contourner ou modifier, tenter de contourner ou modifier, encourager ou aider une autre personne à contourner ou modifier une technologie de sécurité ou un logiciel faisant partie des Services ;
</br>ne pas accéder sciemment aux données disponibles sur les Services qui ne vous sont pas destinées ;
</br>ne pas tenter de sonder, analyser ou tester la vulnérabilité de toute partie des services ni enfreindre les mesures de sécurité ou d'authentification sans autorisation préalable ;
</br>ne pas imiter ou tenter d'usurper l'identité d'Eclipsa ou de ses employés (y compris, sans limitation, par l'utilisation d'adresses de messagerie associées à l'un de ces éléments) ;
</br>ne pas utiliser le compte, le nom d'utilisateur ou le mot de passe d'un autre utilisateur des Services à tout moment ;
</br>vous êtes seul responsable de tous les frais et dépenses que vous pourriez connaître en relation avec votre utilisation des Services et vous êtes seul responsable de la confidentialité de votre mot de passe et des autres informations de votre compte ;
</br>vous ne fournirez pas à Eclipsa des informations d'achat fausses, inexactes, fabriquées, contrefaites, falsifiées, ou autrement artificielles ou non-authentiques dans le but de réclamer le remboursement d'une Offre Eclipsa ;
</br>vous n'utiliserez ni n'essayerez d'utiliser une offre Eclipsa pour des produits qui n'ont pas été réellement achetés et conservés, ou pour des produits qui ont été achetés mais qui ont été retournés à un magasin ou à un fournisseur en ligne, après l'achat ;
</br>vous ne téléchargerez pas sur Eclipsa des reçus qui ont déjà été téléchargés par vous ou un autre utilisateur, dans le but d’obtenir un crédit auprès d'Eclipsa pour l'achat de produits que vous n'avez pas achetés ;
</br>vous ne téléchargerez pas de reçus sur Eclipsa qui correspondent à des achats effectués plus d'une (1) semaine avant la date à laquelle le reçu a été téléchargé ;
</br>vous ne téléchargerez pas sur Eclipsa des reçus correspondant à des achats antérieurs à la date de lancement d'une Offre Eclipsa, sauf autorisation explicite mentionnée dans les détails de l’Offre ;
</br>vous ne tenterez pas de masquer la date d'un reçu, par exemple en téléchargeant volontairement un reçu sans indiquer la date ou sans indiquer une date clairement lisible ;
</br>nous nous réservons le droit d'empêcher ou de suspendre votre accès aux Services si vous ne respectez pas l'une des parties des présentes conditions d’utilisation on ou de toute loi applicable.
</br>
</br>Eclipsa se réserve le droit de modifier, suspendre ou résilier tout ou partie du programme d’Offres Eclipsa ou des Services, de façon temporaire ou définitive, à tout moment ou de temps à autre, avec ou sans préavis et sans engager notre responsabilité à votre égard. Sans limiter ce qui précède, Eclipsa peut mettre fin au programme d’Offres Eclipsa à sa seule discrétion, ce qui pourrait entraîner la perte de toute remise en argent accumulée conformément aux présentes conditions d’utilisation. Eclipsa se réserve le droit de modifier ou de remplacer les conditions d’utilisations contenues dans les présentes Conditions d’Utilisation ou l'une de ses politiques, pratiques et directives relatives au programme d’Offres Eclipsa ou aux Services. Nous nous réservons le droit, à notre seule et absolue discrétion, d'apporter des modifications de temps à autre et sans préavis à la manière dont nous proposons et exploitons nos Services. Vous acceptez qu’Eclipsa ne soit pas responsable envers vous pour toute modification ou cessation du programme d’Offres Eclipsa ou des Services. Vous reconnaissez qu'Eclipsa n'a aucune obligation explicite ou implicite de fournir, ou de continuer à fournir, le programme d’Offres Eclipsa ou les services, ou une partie de ceux-ci, que ce soit maintenant ou à l'avenir. Dans la mesure où ce qui précède nécessite une modification des présentes conditions d'utilisation, cette modification sera effectuée conformément à l'article 14.
</br>
</br><strong>Connexion au Compte et Inactivité </strong>
</br>
</br>L’utilisation des Services n'est possible qu’avec la création d'un compte avec un nom d'utilisateur et un mot de passe. Vous êtes seul responsable de toute utilisation ou utilisation abusive de votre nom d'utilisateur et de votre mot de passe et de toutes les activités qui se produisent sous votre nom d'utilisateur et votre mot de passe. Vous ne pouvez pas transférer ou céder de quelque façon votre compte à un tiers. Pour des raisons de sécurité, vous devez garder votre identifiant et votre mot de passe confidentiels et ne pas les divulguer à quiconque ni permettre à quiconque de les utiliser, à l'exception d'un représentant autorisé d'Eclipsa. Eclipsa vous recommande de choisir un mot de passe qui vous est propre et qui ne soit pas facile à deviner. Vous devez changer votre mot de passe régulièrement et vous devez vous déconnecter à la fin de chaque session.
</br>
</br>Vous devez immédiatement informer Eclipsa de toute utilisation non autorisée de votre nom d'utilisateur ou de votre mot de passe, ou si vous savez ou soupçonnez que votre nom d'utilisateur ou votre mot de passe a été perdu ou volé, qu'il a été découvert par une autre personne ou qu'il a été compromis. Tous les noms de connexion et mots de passe restent la propriété d'Eclipsa. Eclipsa n'est pas tenu de vérifier l'identité ou l'autorité réelle de l'utilisateur avec un nom d'utilisateur ou un mot de passe.
</br>
</br>Si vous ne réclamez pas valablement une Offre Eclipsa au cours d'une période de douze (12) mois, nous considérerons votre compte comme inactif. Si votre compte est considéré comme inactif, nous avons le droit, sans autre préavis, d'appliquer des frais de gestion de compte inactif d'un montant égal à 2 $ de Remises par mois. Les frais de gestion de compte inactif seront imputés au solde actuel de vos Remises, à condition que votre solde ne soit pas négatif. Une fois que vous réclamez une Offre d’Eclipsa sur votre compte, votre compte devient actif et aucun autre frais de gestion de compte ne sera appliqué à moins que et jusqu'à ce que votre compte soit réputé inactif, comme décrit ci-dessus.
</br>
</br><strong>Mobile</strong>
</br>Eclipsa propose des applications mobiles comme moyen rapide et facile pour nos utilisateurs d'accéder à nos Services. Nos applications mobiles peuvent inclure la possibilité d'accéder aux Services et de télécharger du contenu sur les Services, de recevoir du contenu à partir des Services et de télécharger des applications sur votre appareil sans fil (collectivement dénommées les « Fonctionnalités Mobiles »). Des frais standard de messagerie, de données et autres peuvent être facturés par votre opérateur pour participer aux Fonctionnalités Mobiles. Des frais peuvent apparaître sur votre facture de services sans fil ou être déduits de votre solde prépayé. Votre opérateur peut interdire ou restreindre certaines Fonctionnalités Mobiles et certaines d’entre elles peuvent être incompatibles avec votre opérateur ou votre appareil. Vous devriez vérifier auprès de votre opérateur pour savoir quels forfaits sont disponibles et combien ils coûtent ou pour toute autre question concernant ces problèmes liés au transporteur. En utilisant les Fonctionnalités Mobiles, vous acceptez que nous communiquions avec vous concernant les Services par SMS ou par un autre moyen électronique sur votre téléphone cellulaire.
</br>
</br><strong>Propriété, Utilisation et Droits de Propriété Intellectuelle</strong>
</br>Ces Services et tous les droits de propriété intellectuelle sur les Services (y compris, sans limitation, tout Contenu) sont notre propriété et/ou de nos licenciés. Nous et nos licenciés réservons tous nos droits de propriété intellectuelle (y compris, sans limitation, tous les droits d'auteur, marques commerciales, noms de domaine, droits de conception (design), droits de base de données, brevets et tous autres droits de propriété intellectuelle de quelque nature que ce soit), qu'ils soient enregistrés ou non, quel que soit le territoire.
</br>
</br>Rien dans ces conditions d’utilisation ne vous accorde des droits sur les Services autres que ceux nécessaires pour vous permettre d'accéder aux Services. Vous vous engagez à ne pas modifier et/ou essayer de contourner ou de supprimer les avis et notices de propriété intellectuelle contenus dans les Services, et en particulier les droits numériques ou toute autre technologie de sécurité intégrée ou contenue dans le Contenu des Services.
</br>
</br>Marques Commerciales – « Eclipsa » et toutes les mots et/ou les logos associées sont nos marques commerciales, ainsi que les marques commerciales figurant sur notre site et les applications mobiles de nos différentes sociétés partenaires qui peuvent proposer des produits, des services et/ou des événements afin de participer à notre programme d’Offres Eclipsa sont des marques commerciales de ces sociétés. D'autres marques et noms commerciaux peuvent également être utilisés sur ces Services. L'utilisation ou la mauvaise utilisation de toute marque commerciale ou de tout autre contenu sur les Services, à l'exception de ce qui est prévu dans les présentes conditions, est strictement interdite. Aucune information contenue dans les Services ne saurait être interprétée comme accordant, implicitement, par estoppel ou autrement, une licence ou un droit d'utiliser une marque commerciale sans notre autorisation écrite préalable et/ou l'autorisation de nos sociétés partenaires.
</br>
</br>Commentaires – Eclipsa peut, à sa seule discrétion, permettre à ses utilisateurs de fournir à Eclipsa des commentaires par courriel ou par un autre moyen (« Contenu Utilisateur »). Si vous fournissez du Contenu Utilisateur, vous accordez à Eclipsa un droit non-exclusif, sans redevances ni frais, perpétuel, irrévocable et sous-licenciable en totalité, d’utiliser, supprimer, reproduire, modifier, adapter, publier, traduire, créer des œuvres dérivées, distribuer et afficher ce Contenu Utilisateur dans le monde entier sur tous les supports, connus ou non encore connus, à quelque fin que ce soit, commerciale ou autre, sans indemnisation ni pour vous ni pour une autre personne, sans aucune responsabilité, et sans aucune obligation de confiance, et vous renoncez tous les droits moraux sur le Contenu Utilisateur. Vous accordez également à Eclipsa le droit d’utiliser le nom que vous avez soumis avec le Contenu Utilisateur, le cas échéant, en rapport avec les droits d’Eclipsa définis dans cette section.
</br>
</br><strong>Logiciel</strong>
</br>Lorsqu'un logiciel est disponible en téléchargement à partir des Services, il s'agit de notre travail protégé par le droit d'auteur et/ou celui de nos licenciés. Vous ne pouvez utiliser ce logiciel que conformément aux termes de l'accord de licence d’utilisateur final qui l'accompagne et, sous réserve de cet accord, aux termes des présentes conditions. Tous ces logiciels sont mis à disposition en téléchargement uniquement pour votre usage personnel, de manière non commerciale ou publique. Toute reproduction ou redistribution de logiciel non conforme au contrat de licence d’utilisateur final et/ou aux présentes conditions est expressément interdite et peut entraîner des sanctions civiles et pénales. Nos logiciels ne sont garantis que dans la mesure expressément requise par la loi canadienne ou selon les termes du contrat de licence d’utilisateur final. Par la présente, nous déclinons toutes autres garanties, conditions et autres termes (explicites ou implicites) concernant le logiciel, y compris toutes les garanties implicites, exemptes de tout défaut et d’adéquation à un usage particulier. En aucun cas, notre responsabilité globale à l’égard de ce logiciel n’excédera les limites de responsabilité stipulées dans le contrat de licence d’utilisateur final applicable ou, dans tous les autres cas, à 100 $ au total.
</br>
</br><strong>Exactitude des Informations et Disponibilité des Services</strong>
</br>Bien que nous déployions des efforts raisonnables pour présenter des informations exactes et à jour sur les Services, nous ne représentons, ne garantissons ni ne promettons (explicitement ou implicitement) que le Contenu soit ou reste disponible, exact, complet et à jour, exempts de bugs, d'erreurs ou d'omissions, d'adéquation ou d'utilisation quelconque. Toute confiance que vous pouvez accorder en relation aux informations sur ces Services est à vos propres risques et nous pouvons suspendre ou mettre fin à l'exploitation des Services à tout moment et à notre seule discrétion. Rien dans ces conditions d’utilisation ne doit être préjudiciable aux exigences légales ou à vos droits légaux. Le Contenu des Services est fourni uniquement à des fins d'information générale et pour vous informer de notre société et de nos produits, de nos actualités, fonctionnalités, services et autres sites susceptibles de vous intéresser. Il ne s'agit en aucun cas d'un conseil technique, financier ou juridique, ni d'aucun autre type de conseil.
</br>
</br>Bien que nous fassions des efforts commerciaux raisonnables pour s’assurer que les Services sont disponibles, nous ne représentons ni ne garantissons de quelque manière que ce soit, la disponibilité continue des Services à tout moment, ni l’utilisation ininterrompue des Services.
</br>
</br><strong>Liens hypertextes et Sites Tiers</strong>
</br>Les Services peuvent contenir des hyperliens ou des références à des sites tiers autres que ceux des Services. De tels hyperliens ou références sont fournis uniquement pour votre expérience. Nous n’avons aucun contrôle sur les sites de tiers et n’assumons aucune responsabilité pour le contenu, le matériel ou les informations qu’ils contiennent. L'affichage de liens hypertextes et de références à des sites tiers ne constitue en aucun cas une approbation dudit site, des produits ou des services de ces tiers. Votre utilisation d'un site tiers peut être régie par les conditions d’utilisation de ce site tiers.
</br>
</br><strong>es et Limitation de Responsabilité</strong>
</br>Vous acceptez que votre utilisation des Services se fasse « dans la mesure des disponibilités ». Comme indiqué ci-dessus, sauf disposition contraire expresse de la loi applicable, nous ne faisons aucune déclaration, garantie ou autre condition (expresse ou implicite) en relation avec la fourniture des Services, y compris, sans limitation, en ce qui concerne la complétude, la qualité satisfaisante ou l’adéquation des Services à un usage particulier.
</br>
</br>Dans la mesure maximale permise par la loi applicable, nous excluons toute responsabilité (contractuelle, délictuelle, issues d’obligations légales ou autre) que nous pourrions avoir à votre égard en raison d'une erreur ou d’une inexactitude dans tout Contenu, pour l'indisponibilité des Services pour quelque raison que ce soit, et pour toute déclaration faite sur les Services.
</br>
</br>Nous ne serons pas responsables des pertes ou dommages que nous causons et que nous ne pouvions raisonnablement pas anticiper lorsque vous avez commencé à utiliser les Services, par exemple si vous perdez des revenus, un salaire, des bénéfices ou une réputation en raison de votre utilisation des Services et/ou des actes ou des omissions de tiers, tels que d'autres utilisateurs des Services, ou de tout autre dommage indirect ou consécutif que vous pourriez subir en ce qui concerne les Services et leur Contenu.
</br>
</br>En aucun cas, notre responsabilité totale envers vous pour toute réclamation issue de votre utilisation des Services (y compris le téléchargement ou l'utilisation de tout Contenu) ne dépassera 100 $.
</br>
</br>En aucun cas, Eclipsa et/ou ses différentes sociétés affiliées, licenciés, sociétés partenaires ou autres tiers impliqués dans la prestation des Services ne pourront être tenus responsables de quelque dommage, perte, réclamation, préjudice, ou de tout acte spécial, exemplaire, punitif, dommages directs ou indirects, accessoires ou consécutifs de toute nature (y compris, sans limitation, la perte économique, perte de profit ou d’épargne), qu’ils soient contractuels, délictuels, de responsabilité stricte ou autre, et qui sont issus ou ont un lien quelconque avec toute utilisation des Services ou tout échec ou retard dans le fonctionnement des Services, même s’ils sont informés de la possibilité de dommages et intérêts.
</br>
</br>Toutes les exclusions et limitations de responsabilité des présentes Conditions d’Utilisation sont soumises aux Conditions Additionnelles en ce qui concerne les matières couvertes par ces Conditions Additionnelles et tel que requis par la loi.
</br>
</br><strong>Durée et Résiliation</strong>
</br>La durée de ces Conditions d’Utilisation commence dès votre acceptation et se poursuit jusqu'à sa résiliation conformément aux présentes.
</br>
</br>Vous acceptez qu’Eclipsa, à sa seule discrétion, peut résilier ou suspendre les présentes Conditions d’Utilisation et/ou de votre compte, en tout ou en partie, y compris votre utilisation des Services, pour quelque raison que ce soit, notamment (a) si votre compte est réputé inactif pendant une période de 12 mois ou plus, (b) si Eclipsa estime que vous avez enfreint les présentes Conditions d’Utilisation ou agi de manière incompatible avec les présentes Conditions d’Utilisation, (c) si nous recevons des demandes émanant des forces de l'ordre ou d'autres agences gouvernementales, (d) en vertu d’une demande de votre part (suppression automatique de compte), (e) pour une interruption ou modification importante des Services (ou de toute partie de ceux-ci), (f) en raison de problèmes techniques ou sécurité inattendus, (g) pour assurer une conformité avec une procédure légale ; ou (h) si vous avez ou si nous pensons que vous avez exercé des activités illégales, y compris, sans limitation, des fraudes. Eclipsa peut également, à sa seule discrétion et à tout moment, cesser de fournir les Services, en tout ou en partie, avec ou sans préavis. Vous reconnaissez et acceptez que toute résiliation de votre accès aux Services en vertu de toute disposition des présentes Conditions d’Utilisation puisse être effectuée sans préavis. De plus, vous acceptez qu'Eclipsa ne soit pas responsable envers vous pour la résiliation de votre compte ou pour la résiliation de votre accès aux Services, sauf stipulation expresse contraire des présentes.
</br>
</br>En cas de résiliation de votre compte ou de votre accès aux Services, toute transaction liée à une Offre Eclipsa en cours peut être résiliée avant l’heure de fin indiquée. Si votre compte est résilié pour violation des Conditions d’Utilisation, l'intégralité de votre solde de Remises en espèces sera annulé et réputé nul. Si votre compte est résilié sur votre demande, il vous incombera de faire une demande (sous réserve de l'exigence de notre solde minimal de 20 $ de Remise décrit ci-dessus) avant de demander une telle annulation. Si votre compte est résilié autrement que pour violation des Conditions d’Utilisation ou à votre demande, et que vous disposez d'un solde d'au moins 20 $ en Remises, nous déploierons tous les efforts commerciaux raisonnables pour vous en aviser et pour vous fournir au moins 30 jours à compter de la date de l'avis pour faire une demande d'échange de votre solde de Remises conformément aux présentes Conditions d’Utilisation. Si vous ne faites pas de Demande dans le délai imparti, vous perdrez l'intégralité de votre solde de Remises ainsi que le droit de faire une Demande.
</br>
</br>Tous les Conditions d’Utilisation énoncées dans les présentes qui, de par leur nature, devraient survivre à la résiliation (y compris, sans limitation, toutes les dispositions relatives à la propriété intellectuelle, à la propriété et à l'indemnisation) afin d'être pleinement appliquées, resteront en vigueur après la date d'expiration ou de résiliation des Conditions d’Utilisation.
</br>
</br><strong>Général</strong>
</br>Ces conditions sont datées du [date ]. Aucune modification de ces conditions n'est valide ou n'a d'effet que si nous en avons convenu par écrit. Nous nous réservons le droit de modifier ces conditions d’utilisation de temps à autre. Nos nouvelles conditions seront affichées sur les Services et en continuant à utiliser et à accéder aux Services après de tels changements, vous acceptez d'être lié par toute modification de notre part. Il est de votre responsabilité de vérifier ces conditions d’utilisation de temps à autre pour vérifier ces changements.
</br>
</br>Si vous n'acceptez pas les conditions d’utilisation révisées, vous pouvez annuler votre compte et faire une demande pour réclamer le solde de vos points de Remise sous réserve de l'exigence de notre solde minimal de 20 $. Votre utilisation continue des Services signifie que vous acceptez toutes les conditions d’utilisation révisés. Vous devriez vérifier régulièrement les conditions pour prendre connaissance la version la plus récente.
</br>
</br>Sauf stipulation contraire expresse des présentes Conditions d’Utilisation, tous les avis et requêtes que vous nous envoyez doivent être soumis via notre système du Centre d'assistance, sur notre site et/ou sur une application mobile. Tous les avis que nous vous adressons seront affichés de temps en temps sur notre site et/ou sur les applications mobiles.
</br>
</br>Nous n'assumerons aucune responsabilité envers vous pour toute violation de ces conditions causée par un événement ou des circonstances indépendants de notre volonté, y compris, sans limitation, de grèves, lock-out et autres conflits de travail, panne des systèmes ou d'accès au réseau, ou en cas d'inondation, d’incendie, d’explosion ou d’accident.
</br>
</br>Si une partie quelconque de ces conditions d’utilisation est inapplicable (y compris toute disposition dans laquelle nous excluons ou limitons notre responsabilité envers vous), la force exécutoire de toute autre partie de ces conditions d’utilisation ne sera pas affectée. Si nous choisissons de ne faire respecter aucun des droits que nous avons à votre encontre, cela ne nous empêchera pas de décider plus tard d’exercer ou de faire respecter ce droit.
</br>
</br>Ces Conditions d’Utilisation (ainsi que la Politique de Confidentialité et tous les Conditions Additionnelles applicables ou autres termes expressément mentionnés dans ces conditions et incorporés par référence) contiennent l'intégralité de l'accord entre nous et vous en ce qui concerne votre utilisation des Services, et remplacent et supplémentent toute déclaration ou autre communication (écrite ou autre) faite par vous ou par nous qui ne figure pas dans le présent document. À l'exception d'une fraude ou d'une déclaration frauduleuse, nous ne serons pas tenus responsables du caractère faux ou trompeur de cette déclaration.
</br>
</br>Vous ne pouvez pas céder, concéder en sous-licence ou transférer de quelque manière que ce soit vos droits et obligations découlant de ces conditions à une quelconque autre personne.
</br>
</br>Les présentes conditions doivent être interprétées et régies conformément aux lois en vigueur en Ontario, Canada.
</br>
</br><strong>Questions ou Commentaires</strong>
</br>Si vous avez des questions ou commentaires sur ces conditions d’utilisation ou toute question relative aux Services ou autres Offres Eclipsa, veuillez cliquer sur le lien du Centre d’assistance ou nous envoyer un courriel à l'adresse : assistance@eclipsa.com
</br>
</br>Date de la dernière modification: 1 avril 2020
</br>
`,
  },
}
