import termsLocalization from './termsLocalization'
import privacyLocalization from './privacyLocalization'

const localization = {
  en: {
    ...termsLocalization.en,
    ...privacyLocalization.en,
    about: 'About',
    aboutContent:
      'Eclipsa is a consumer technology company headquartered in Toronto, Ontario. Eclipsa is a free smartphone app that enables its members to earn cash-back on their purchases. Eclipsa partners with industry leaders to bring the best products available to its members and to help them make better lifestyle choices more affordable. Our mission is to help people live better while saving money. We actively seek out partner brands that have a social, environmental and/or wellness focus.',
    alreadyHaveAnAcc: 'Already have an account?',
    alerts: 'Alerts',
    amountToBeWithdraw: 'Ammount to be withdrawn',
    any: 'Any',
    avocado: 'Avocado',
    back: 'Back',
    balance: 'Balance',
    banana: 'Bananas',
    blog: 'Blog',
    bread: 'Bread',
    businessPartnerships: 'Business Partnerships',
    buyAnywhere: 'Buy anywhere',
    cancel: 'Cancel',
    cashBack: 'cash back',
    cashOut: 'Cash Out',
    cashOutByCheque: 'Cash out by Cheque',
    cashOutByChequeDetail: 'Confirm your Cheque Details',
    cashOutByEmail: 'Enter your email for the Interac e-Transfer',
    cashOutByETransfer: 'Cash out with',
    cashOutNow: 'Cash Out Now',
    cashOutMinimum: '(minimum $20.00 required to cash out)',
    cashOutSuccess: 'Thanks for Cashing Out!',
    cashOutSuccessByChequeMsg:
      'You should receive your cheque in 10-15 days. If you have not received your cheque after 15 days, please contact us at help@eclipsa.com',
    cashOutSuccessByEmailMsg:
      'Your available balance is being sent to Interac and should be transferred to you within 1 day. Interac will send you a confirmation email.',
    chat: 'Chat',
    changePassword: 'Change Password',
    chequeAddress: 'Address (street number and name)',
    chequeCity: 'City/Town',
    chequeProvince: 'Province',
    chooseYourFavourite: 'Choose your favourite deals and buy anywhere',
    clipped: 'Clipped',
    clipIt: 'Clip it',
    clipSpan:
      "Deals you've clipped will also show up as 'Clipped' on your smartphone.",
    contact: 'Contact',
    confirmPassword: 'Confirm Password',
    customerSupport: 'Customer Support',
    dateOfBirth: 'Birthday',
    deals: 'Deals',
    dealsModalExpire: 'This deal expires on',
    dealsExpire: 'Deals expire every Wednesday at midnight!',
    downloapTheApp: 'Download the App',
    downloapTheAppSub:
      'Download our free app & choose the products you want to buy',
    done: 'Done',
    earnCbOnProductsYouLove: 'A Fun, Free & Better Way to Save',
    earnCBOnYourFavBrands:
      'Earn cash back on your healthier grocery choices too. New products are added every week on Thursday.',
    earnings: 'Earnings',
    eclipsaTM: '@ Eclipsa 2019',
    email: 'Email',
    emailAddress: 'Email Address',
    emailHelp: 'help@eclipsa.com',
    emailPartners: 'business@eclipsa.com',
    english: 'English',
    enterYourNumber: 'Enter your number',
    enterYourNumberSpan:
      'Enter your number below to receive a text invite to the Eclipsa App. (it’s free!)',
    expiry: 'Expiry',
    favBrands: 'Fresh Savings',
    findDeals: 'Find Deals',
    firstName: 'First Name',
    french: 'French',
    forgotPassword: 'Forgot your password?',
    forgotPasswordSpan: `Enter your email below and we'll send you a reset link`,
    funFreeWay: 'A Healthy Serving of Cash Back',
    getCashBack: 'Get Cash Back!',
    getCashBackSub: `After we read your receipt, we'll deposit the cash into your account`,
    getPaid: 'Get paid in Cash or Interac',
    help: 'Help',
    howItWorks: 'How it Works',
    inputNotMatch: `Input does not match`,
    invalidEmail: 'Invalid email address',
    invalidPhoneNumber:
      'Please input a valid 10 digit phone number as 1231231234',
    invalidPostalCodeCa: 'Please input a valid Canadian postal code',
    inviteMe: 'Invite me',
    lastName: 'Last Name',
    limit: 'Limit: ',
    liveBetterAndSave: 'Live Better and Save',
    login: 'Login',
    loginWithFb: 'Login With Facebook',
    logout: 'Logout',
    minValue: 'Should be greater than ',
    minLength: 'Minimum length should be ',
    myAccount: 'My Account',
    mustBeNumber: 'Must be a number',
    newPassword: 'Your new Password',
    noAccountYet: 'No account yet?',
    onceYouEarnTwentyDollars:
      'Once you earn $20, we can mail you a cheque or deposit your earnings into your bank account through Interac e-Transfer.',
    passwordLogin: 'Password',
    password: 'Password (minimum 8 characters)',
    pending: 'Pending',
    postalCode: 'Postal Code',
    privacy: 'Privacy',
    purchaseFromYourFavStores:
      'You can purchase your products at your favourite stores, retailers, and even online!',
    required: 'Required',
    resetPassword: 'Reset password',
    selectLanguage: 'Select Language',
    signup: 'Sign Up',
    signupWithFb: 'Sign Up with Facebook',
    size: 'Size: ',
    startSavingNow: 'Start Saving Now!',
    store: 'Stores: ',
    takeReceiptPicture: 'Take a Picture of your Receipt',
    takeReceiptPictureSub:
      'Use the eclipsa app to snap a clear picture of your receipt',
    talkToOurCustomerCupport: 'Talk to our customer support',
    tellYourFriends: 'Tell your Friends!',
    termsNConditions: 'Terms & Conditions',
    testimonialJessica: `I'm happy to find that there are deals on healthy products every week.`,
    testimonialBrianna:
      'I saved a lot of money for my family by using Eclipsa.',
    testimonialJennifer: `At first, I didn't realize how easy it was to use and now I'm using Eclipsa every week.`,
    testimonialAshley:
      'My money was e-transferred to my bank account so I could use it right away to treat myself.',
    testimonialSarah:
      'I like the fact that I can earn money by buying these products pretty much anywhere.',
    update: 'Update',
    updateYourEmail: 'Update your Email',
    updateYourName: 'Update your Name',
    updatePassword: 'Update your Password',
    updatePasswordButton: 'Update Password',
    updateProfileButton: 'Update Profile',
    updateYourPostalCode: 'Update your Postal Code',
    workIntroNote:
      '(you can cash out by Interac e-Transfer or Cheque as soon as you earn $20)',
    yourNewPassword: 'Your new password',
    yourProfile: 'Your Profile',
    moreInfo: 'More info',
    noDeal: 'Congratulations!',
    noDeal1: 'You\'ve claimed all the deals this week!'
  },
  fr: {
    ...termsLocalization.fr,
    ...privacyLocalization.fr,
    about: 'À propos',
    aboutContent: `Eclipsa est une application gratuite pour téléphone intelligent qui permet à ses membres d’obtenir une remise en argent sur leurs achats. Eclipsa s'associe aux leaders de l'industrie pour proposer les meilleurs produits à ses membres et leurs aider à rendre les meilleurs choix de vie plus abordables. Notre mission est d'aider les gens à mieux vivre tout en économisant. Nous travaillons avec des partenaires qui se concentrent sur la santé et le bien-être, les produits bio, l'impact social et / ou la durabilité.`,
    alreadyHaveAnAcc: 'Déjà abonné(e)?',
    alerts: 'Notifications',
    amountToBeWithdraw: 'Montant à encaisser',
    any: 'Tous',
    avocado: 'Avocat',
    back: 'Retour',
    balance: 'Solde',
    banana: 'Bananes',
    blog: 'Blog',
    bread: 'Pain',
    businessPartnerships: "Partenariats d'affaires",
    buyAnywhere: 'Magasinez n’importe où',
    cancel: 'Annuler',
    cashBack: 'de remise',
    cashOut: 'Encaisser',
    cashOutByCheque: 'Retour en argent par chèque',
    cashOutByChequeDetail: 'Confirmez votre information',
    cashOutByEmail: 'Courriel du destinataire',
    cashOutByETransfer: 'Retour en argent par',
    cashOutNow: 'Encaisser maintenant',
    cashOutMinimum: '(un minimum de 20,00 $ est requis pour encaisser)',
    cashOutSuccess: 'Bravo pour vos économies!',
    cashOutSuccessByChequeMsg: `Vous devriez recevoir votre cheque d'ici 10 à 15 jours. Si vous n'avez pas recu votre chèque après 15 jours, veuillez nous écrire à aide@eclipsa.com`,
    cashOutSuccessByEmailMsg: `Le montant a été envoyé à Interac et devrait apparaître dans votre compte d'ici un jour ouvrable. Vous recevrez un courriel de confirmation de Interac. `,
    chat: 'Clavarder',
    changePassword: 'changer le mot de passe',
    chequeAddress: 'Adresse (numéro et rue)',
    chequeCity: 'Ville/Municipalité',
    chequeProvince: 'Province',
    chooseYourFavourite:
      ' Choisissez vos offres préférées et magasinez où vous le voulez',
    clipped: 'Favorisé',
    clipIt: 'Favori',
    clipSpan:
      'Vos offres favorisées apparaissent aussi sur votre téléphone intélligent',
    confirmPassword: 'Réinitialiser mon mot de passe',
    contact: 'Contactez-nous',
    customerSupport: 'Pour nos membres',
    dateOfBirth: 'Anniversaire',
    deals: 'Offres',
    dealsExpire: 'Deals expire every Wednesday at midnight!',
    dealsModalExpire: 'Cette offre expire le',
    downloapTheApp: 'Téléchargez l’Appli',
    downloapTheAppSub:
      'Téléchargez notre appli gratuite et sélectionnez vos offres préférées',
    done: 'Terminer',
    earnCbOnProductsYouLove: `À votre santé et bien être`,
    earnCBOnYourFavBrands: `Obtenez une remise d’argent sur votre épicerie ainsi que vos choix plus sains. De nouvelles offres tous les jeudis.`,
    earnings: 'Vos gains',
    eclipsaTM: '@ Eclipsa 2019',
    email: 'Courriel',
    emailAddress: 'Courriel',
    emailHelp: 'aide@eclipsa.com',
    emailPartners: 'business@eclipsa.com',
    english: 'Anglais',
    enterYourNumber: 'Votre numéro',
    enterYourNumberSpan: `Mettre votre numéro ci-dessous pour recevoir une invitation texto à l'appli Eclipsa!`,
    expiry: 'Expiration',
    favBrands: 'Des produits frais',
    findDeals: 'Les offres',
    firstName: 'Prénom',
    french: 'Français',
    forgotPassword: 'Mot de passe oublié?',
    forgotPasswordSpan: `Mettez votre courriel pour recevoir un lien de réinitialisation`,
    funFreeWay: 'Santé à vos remises d’argent',
    getCashBack: 'Encaissez!',
    getCashBackSub: `Dès que nous avons lu votre reçu, nous déposerons l’argent dans votre compte Eclipsa`,
    getPaid: 'Encaissez vos remises accumulées',
    help: `Besoin d'aide?`,
    howItWorks: 'Comment ça marche',
    inputNotMatch: `L'entrée ne correspond pas`,
    invalidEmail: 'Adresse e-mail invalide',
    invalidPhoneNumber:
      'Veuillez entrer un numéro de téléphone valide à 10 chiffres 1231231234',
    invalidPostalCodeCa: 'Veuillez entrer un code postal canadien valide',
    inviteMe: 'M’envoyez un texto',
    lastName: 'Nom de famille',
    limit: 'Limite: ',

    liveBetterAndSave: 'Vivez mieux tout en économisant',
    login: 'Connexion',
    loginWithFb: 'Connexion avec Facebook',
    logout: 'Déconnexion',
    minValue: `Devrait être supérieur à`,
    minLength: `La longueur minimale devrait être `,
    myAccount: 'Mon compte',
    mustBeNumber: 'Doit être un nombre',
    newPassword: 'Nouveau mot de passe',
    noAccountYet: 'Inscrivez-vous?',
    onceYouEarnTwentyDollars: `Une fois que vous avez accumulé 20 $, nous pouvons vous l'envoyer par virement Interac ou vous envoyer un chèque par la poste`,
    passwordLogin: 'Mot de passe',
    password: 'Mot de passe (minimum 8 caractères)',
    pending: 'En attente',
    postalCode: 'Code Postal',
    privacy: 'Politique de confidentialité',
    purchaseFromYourFavStores:
      'Vous pouvez acheter vos produits dans vos magasins préférés et même en ligne',
    required: 'Requis',
    resetPassword: 'Réinitialisez votre mot de passe',
    selectLanguage: 'Choix de langue',
    signup: 'S’inscrire',
    signupWithFb: 'Inscrivez-vous avec Facebook',
    size: 'Format: ',
    startSavingNow: 'Commencez à économiser maintenant',
    store: 'Magasins: ',
    takeReceiptPicture: 'Prenez une photo de votre reçu',
    takeReceiptPictureSub:
      'Utilisez l’appli Eclipsa pour prendre une photo de votre reçu',
    talkToOurCustomerCupport: 'Parlez à notre support client',
    tellYourFriends: 'Parlez-en à vos amis!',
    termsNConditions: 'Conditions d’utilisation',
    testimonialJessica: `Enfin! J'arrive à trouver des offres sur des produits sains chaque semaine.`,
    testimonialBrianna: `J'ai economisé beaucoup d'argent pour ma famille en me servant d'Eclipsa.`,
    testimonialJennifer: `Au debut, j'ignorais a quel point c'etait facile de s'en servir et maintenant, j'utilise Eclipsa à chaque semaine.`,
    testimonialAshley: `L'argent a ete déposé dans mon compte de banque et j'ai pu m'en servir tout de suite.`,
    testimonialSarah: `J'aime surtout le fait de gagner de l'argent en achetant ces produits un peu partout.`,
    update: 'Choix de langue',
    updateYourEmail: 'Mettre à jour votre courriel',
    updateYourName: 'Mettre à jour votre nom',
    updatePassword: 'Mettre à jour votre mot de passe',
    updatePasswordButton: 'Mettre à jour votre mot de passe',
    updateProfileButton: 'Mettre à jour votre Profil',
    updateYourPostalCode: 'Mettre à jour votre code postal',
    workIntroNote:
      '(recevez un virement interact ou chèque dès que vous avez accumulé 20 $)',
    yourNewPassword: 'Nouveau mot de passe',
    yourProfile: 'Profil',
    moreInfo: 'Plus d\'info',
    noDeal: 'Félicitations!',
    noDeal1: 'Vous avez réclamé toutes les offres de la semaine!'
  },
}

export default {
  get: (language = 'en', key) => {
    if (key && language in localization && key in localization[language]) {
      return localization[language][key]
    }
    return ''
  },
  defaultLanguage: 'en',
}
