import { toast } from 'react-toastify'

import api from '../services/api'
import { getUser } from '../store/data'

export const CHANGE_MODAL_HOWITWORKS = 'CHANGE_MODAL_HOWITWORKS'

export const REGISTER_LOADING = 'REGISTER_LOADING'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAILURE = 'REGISTER_FAILURE'

export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE'

export const LOGIN_LOADING = 'LOGIN_USER'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'

export const LOGOUT_USER = 'LOGOUT_USER'

export const FACEBOOK_LOGIN_LOADING = 'FACEBOOK_LOGIN_LOADING'
export const FACEBOOK_LOGIN_SUCCESS = 'FACEBOOK_LOGIN_SUCCESS'
export const FACEBOOK_LOGIN_FAILURE = 'FACEBOOK_LOGIN_FAILURE'

export const GET_ME_SUCCESS = 'GET_ME_SUCCESS'
export const GET_ME_FAILURE = 'GET_ME_FAILURE'

export const UPDATE_PROFILE_SUCCESS = 'UPDATE_PROFILE_SUCCESS'
export const UPDATE_PROFILE_FAILURE = 'UPDATE_PROFILE_FAILURE'
export const UPDATE_PROFILE_LOADING = 'UPDATE_PROFILE_LOADING'

export const UPDATE_PROFILE_PIC_SUCCESS = 'UPDATE_PROFILE_PIC_SUCCESS'
export const UPDATE_PROFILE_PIC_FAILURE = 'UPDATE_PROFILE_PIC_FAILURE'
export const UPDATE_PROFILE_PIC_LOADING = 'UPDATE_PROFILE_PIC_LOADING'

export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_LOADING = 'CHANGE_PASSWORD_LOADING'
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE'

export const CLIP_OFFER_SUCCESS = 'CLIP_OFFER_SUCCESS'
export const CLIP_OFFER_FAILURE = 'CLIP_OFFER_FAILUER'
export const UNCLIP_OFFER_SUCCESS = 'UNCLIP_OFFER_SUCCESS'
export const UNCLIP_OFFER_FAILURE = 'UNCLIP_OFFER_FAILUER'

export const INVITE_ME_LOADING = 'INVITE_ME_LOADING'
export const INVITE_ME_SUCCESS = 'INVITE_ME_SUCCESS'
export const INVITE_ME_FAILURE = 'INVITE_ME_FAILURE'

// change language
export const changeLanguage = language => dispatch => {
  dispatch({ type: CHANGE_LANGUAGE, payload: { language } })
}

//change the display of Modal_howItWorks
export const changeModalHowItWorks = () => dispatch => {
  dispatch({ type: CHANGE_MODAL_HOWITWORKS })
}

//invite user
export const inviteMe = ({ number, language, callback }) => dispatch => {
  dispatch({ type: INVITE_ME_LOADING })

  return api({
    method: 'POST',
    url: `/invite`,
    data: {
      number: number,
      language: language
    },
  })
    .then(resp => {
      if ('success' in resp.data && resp.data.success === true) {
        //success invite
        dispatch({
          type: INVITE_ME_SUCCESS,
        })
        callback(
          `${language === 'fr' ? 'Une invitation a été envoyée' : 'Invitation has been sent'}`,
          true
        )
      } else {
        //failed
        dispatch({
          type: INVITE_ME_FAILURE,
        })
        callback(
          'message' in resp.data
            ? resp.data.message
            : 'Sorry, please try again later',
          false
        )
      }
    })
    .catch(err => {
      dispatch({
        type: INVITE_ME_FAILURE,
      })

      callback(
        'message' in resp.data ? resp.data.message : 'Sorry, please try again',
        false
      )
    })
}

export const unClipOffer = ({ offerId }) => (dispatch, getState) => {
  const token = getState().user.data.token
  return api({
    method: 'DELETE',
    url: `/offers/${offerId}/clip`,
    tokenHeaders: {
      authorization: token,
    },
  })
    .then(resp => {
      //no payload
    })
    .catch(err => {
      dispatch({
        type: CLIP_OFFER_FAILURE,
        payload: {},
      })
      toast.error('Error unclipping deal')
    })
}

export const clipOffer = ({ offerId }) => (dispatch, getState) => {
  const token = getState().user.data.token
  return api({
    method: 'PUT',
    url: `/offers/${offerId}/clip`,
    tokenHeaders: {
      authorization: token,
    },
  })
    .then(resp => {
      //no payload
    })
    .catch(err => {
      dispatch({
        type: CLIP_OFFER_FAILURE,
        payload: {},
      })
      toast.error('Error clipping deal')
    })
}

//login user
export const loginUser = ({ email, password, callback }) => (dispatch, getState) => {
  // loading
  dispatch({ type: LOGIN_LOADING })

  return api({
    method: 'POST',
    url: `/loginWithEmail`,
    data: {
      emailAddress: email,
      password,
    },
  })
    .then(resp => {
      if ('success' in resp.data && resp.data.success === true) {
        // success
        dispatch({
          type: LOGIN_SUCCESS,
          payload: {
            data: resp.data,
          },
        })
      } else {
        // failed
        dispatch({
          type: LOGIN_FAILURE,
          payload: {},
        })
        const message_fr = resp.data.message_fr
          ? resp.data.message_fr
          : 'Mauvais mot de passe, veuillez réessayer'

        'message' in resp.data
          ? getState().user.language === 'en'
            ? callback(resp.data.message, false)
            : callback(message_fr, false)
          : 'Login failed'
      }
    })
    .catch(err => {
      // 5xx or 4xx error
      dispatch({
        type: LOGIN_FAILURE,
        payload: {},
      })

      callback(
        getState().user.language === 'en' ? 'User login failed!' : 'La connexion de l\'utilisateur a échoué',
        false
      )
    })
}

// logout user
export const logoutUser = () => dispatch => {
  dispatch({ type: LOGOUT_USER })
}

// register user
export const registerUser = ({
  firstName,
  lastName,
  email,
  password,
  postalCode,
  callback
}) => (dispatch, getState) => {
  // loading
  dispatch({ type: REGISTER_LOADING })

  return api({
    method: 'POST',
    url: `/registerWithEmail`,
    data: {
      firstName,
      lastName,
      emailAddress: email,
      password,
      postalCode,
    },
  })
    .then(resp => {
      if ('success' in resp.data && resp.data.success === true) {
        // success
        dispatch({
          type: REGISTER_SUCCESS,
          payload: {
            data: resp.data,
          },
        })
      } else {
        // failed
        dispatch({
          type: REGISTER_FAILURE,
          payload: {},
        })

        'message' in resp.data
          ? getState().user.language === 'en'
            ? callback(resp.data.message, false)
            : callback(resp.data.message_fr, false)
          : 'User registration failed!'
      }
    })
    .catch(err => {
      // 5xx or 4xx error
      dispatch({
        type: REGISTER_FAILURE,
        payload: {},
      })

      callback(
        getState().user.language === 'fr' ?
          'L\'enregistrement de l\'utilisateur a échoué!' :
          'User registration failed!'
      )
    })
}

// facebook login
export const facebookLogin = ({ accessToken }) => dispatch => {
  // loading
  dispatch({ type: FACEBOOK_LOGIN_LOADING })

  return api({
    method: 'POST',
    url: `/loginWithFacebook`,
    data: {
      access_token: accessToken,
    },
  })
    .then(resp => {
      if ('success' in resp.data && resp.data.success === true) {
        // success
        dispatch({
          type: FACEBOOK_LOGIN_SUCCESS,
          payload: {
            data: resp.data,
          },
        })
      } else {
        // failed
        dispatch({
          type: FACEBOOK_LOGIN_FAILURE,
          payload: {},
        })

        toast.error(
          'message' in resp.data
            ? resp.data.message
            : 'User registration failed!'
        )
      }
    })
    .catch(err => {
      // 5xx or 4xx error
      dispatch({
        type: FACEBOOK_LOGIN_FAILURE,
        payload: {},
      })

      toast.error('User registration failed!')
    })
}

// get me
// read the user info
export const getMe = () => (dispatch, getState) => {
  const token = getState().user.data.token
  return api({
    method: 'POST',
    url: `/getMe`,
    tokenHeaders: {
      authorization: token,
    },
  })
    .then(resp => {
      dispatch({ type: GET_ME_SUCCESS, payload: { data: resp.data } })
    })
    .catch(err => {
      dispatch({
        type: GET_ME_FAILURE,
        payload: {},
      })
      toast.error('Error fetching user information!')
    })
}

export const updateProfielPic = ({ url }) => (dispatch, getState) => {
  dispatch({ type: UPDATE_PROFILE_PIC_LOADING })

  const token = getState().user.data.token

  return api({
    method: 'POST',
    url: `/updateProfile`,
    tokenHeaders: {
      authorization: token,
    },
    data: {
      profilePictureUrl: url,
    },
  })
    .then(resp => {
      dispatch({
        type: UPDATE_PROFILE_PIC_SUCCESS,
        payload: {
          data: resp.data,
        },
      })
    })
    .catch(err => {
      // 5xx or 4xx error
      dispatch({
        type: UPDATE_PROFILE_PIC_FAILURE,
        // payload: {},
      })

      toast.error('Error updating profile pic!')
    })
}

export const updateProfile = ({
  firstName,
  lastName,
  emailAddress,
  profilePictureUrl,
  dob,
  postalCode,
  callback
}) => (dispatch, getState) => {
  dispatch({ type: UPDATE_PROFILE_LOADING })

  const token = getState().user.data.token
  const userEmail = getState().user.data.user.emailAddress

  return api({
    method: 'POST',
    url: `/updateProfile`,
    tokenHeaders: {
      authorization: token,
    },
    data: {
      firstName,
      lastName,
      emailAddress,
      profilePictureUrl,
      dob,
      postalCode,
    },
  })
    .then(resp => {
      if ('success' in resp.data && resp.data.success === true) {
        // success
        if (userEmail && emailAddress != userEmail) {
          callback(getState().user.language === 'fr' ? 'Courriel changé' : 'Email changed', true)
        }

        dispatch({
          type: UPDATE_PROFILE_SUCCESS,
          payload: {
            // languange:
            data: resp.data,
          },
        })
      } else {
        // failed
        dispatch({
          type: UPDATE_PROFILE_FAILURE,
          payload: {},
        })
        const message = getState().user.language === 'fr' ?
          'Erreur lors de la mise à jour du profil!' : 'Error updating profile!'
        callback('message' in resp.data ? resp.data.message : message, false)
      }
    })
    .catch(err => {
      // 5xx or 4xx error
      dispatch({
        type: UPDATE_PROFILE_FAILURE,
        payload: {},
      })

      callback(getState().user.language === 'fr' ?
        'Erreur lors de la mise à jour du profil!' :
        'Error updating profile!',
        false
      )
    })
}

export const changePassword = ({ password, callback }) => (dispatch, getState) => {
  dispatch({ type: UPDATE_PROFILE_LOADING })

  const token = getState().user.data.token

  return api({
    method: 'PUT',
    url: `/changePassword`,
    tokenHeaders: {
      authorization: token,
    },
    data: {
      password,
    },
  })
    .then(resp => {
      if ('success' in resp.data && resp.data.success === true) {
        // success
        dispatch({
          type: CHANGE_PASSWORD_SUCCESS
        })

        callback(getState().user.language === 'en'
          ? 'Password changed'
          : 'Mot de passe changé', true)
      } else {
        // failed
        const message_fr = resp.data.message_fr
          ? resp.data.message_fr
          : 'Le changement de mot de passe a échoué'

        dispatch({
          type: CHANGE_PASSWORD_FAILURE
        })

        'message' in resp.data
          ? getState().user.language === 'en'
            ? callback(resp.data.message, false)
            : callback(message_fr, false)
          : 'Password change failed'
      }
    })
    .catch(err => {
      console.log(err)
      // 5xx or 4xx error
      dispatch({
        type: CHANGE_PASSWORD_FAILURE,
        payload: {},
      })

      const message_fr = resp.data.message_fr
        ? resp.data.message_fr
        : 'Le changement de mot de passe a échoué'

      'message' in resp.data
        ? getState().user.language === 'en'
          ? callback(resp.data.message, false)
          : callback(message_fr, false)
        : 'Password change failed'
    })
}
