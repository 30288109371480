module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-firebase/gatsby-browser.js'),
      options: {"plugins":[],"features":{"auth":false,"database":false,"firestore":false,"storage":true,"messaging":false,"functions":false}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-136369811-1","head":false,"anonymize":true,"respectDNT":true,"exclude":["/app/forgot","/app/reset_password","/app/login","/app/signup","/app/cashout","/app/alerts","/app/cashoutbycheque","/app/cashoutbyemail","/app/cashoutsuccess"],"pageTransitionDelay":0,"defer":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
