export default {
  en: {
    privacyTitle: 'Privacy Notice',
    privacySubtitle: 'Eclipsa Inc. Privacy Notice',
    privacyContent: `<br />
<br />At Eclipsa, we take your privacy and security seriously. This Privacy Notice applies to personal information Eclipsa Inc., an Ontario corporation with registered address at 20 Camden St., Suite 200, Toronto, ON M5V 1V1, collects through this website www.eclipsa.com and our mobile apps. Below are highlights of our personal information handling practices followed by our detailed Privacy Notice.
<br />
<br />Our policies and practices have been designed to comply with the Personal Information Protection and Electronic Documents Act (Canada) and corresponding provincial privacy acts. 
<br />
<br />We reserve the right to vary this Privacy Notice from time to time. Our new terms will be displayed at www.eclipsa.com/privacy and on our mobile apps, and you agree to be bound by any variation made by us. 
<br />
<br />Privacy Notice Highlights
<br />
<br />Information We Collect
<br />We collect your information from the following sources:
<br />
<br />
<br />information you give us when you fill out forms, such as during online purchases, customer service inquiries and surveys;
<br />information from other sources, such as companies that help us to update our records; and
<br />information that we collect automatically when you visit our website, such as information about your browser settings and other information collected through cookies.
<br />
<br />
<br />How We Use and Disclose Your Information
<br />
<br />
<br />We use your information to provide products and services and to manage our business operations. For example, we use your information in order to fulfill your Cashback Rewards redemptions, answer your inquiries, authenticate you when you sign into online services, prevent loss and fraud, and analyze our business operations and website performance.
<br />We use your information for marketing and promotional purposes, including with our promotional partners.
<br />We combine all the information we collect other than information relating to your browsing history.
<br />We share information with our affiliates who may be outside of Canada and may be subject to laws that are different from those in Canada.
<br />We use third parties to assist us in our operations. We may share your information with these third parties in order to provide services to you.
<br />
<br />
<br />Your Choices
<br />
<br />
<br />We must send you certain notifications in order for us to communicate with you regarding the Services offered to you by us as outlined in our Terms and Conditions (the “Terms”) (www.eclipsa.com/terms)  including email, SMS and/or mobile notifications. You agree to us sending you these notifications these allows you to change your communication preferences with respect to e-mail newsletters and notifications of special offers and products. If you do not agree with or accept any of these terms, you should cease using the Services immediately.
<br />
<br />You may also close your account if you want to withdraw your consent from our further use of your information. We may, however, retain information about your previous transactions for audit purposes, to ensure the integrity of our data and to fulfill legal requirements.
<br />
<br />
<br />How to Contact Us
<br />If you have a privacy question or concern, please click on the link for the Help Centre on our website or mobile apps to access our help section and/or submit questions.
<br />
<br />Detailed Privacy Notice
<br />
<br />About Us
<br />This website www.eclipsa.com is operated by Eclipsa Inc. We are located at 20 Camden St., Suite 200, Toronto, ON M5V 1V1.
<br />If you have a question or complaint about our personal information handling practices, please click on the link for the Help Centre on our website or mobile apps to access our help section and/or submit questions.
<br />In this Privacy Notice, the terms "we", "our" and "us" mean Eclipsa Inc. 
<br />In this Privacy Notice "you" or "your" means the person accessing or using the Eclipsa Services as outlined in our Terms.
<br />
<br />Effective Date and Scope
<br />This Privacy Notice is effective as of [date].
<br />This Privacy Notice governs our practices with respect to personal information we collect through our website www.eclipsa.com and our Eclipsa branded mobile applications.
<br />
<br />What Personal Information Do We Collect?
<br />Personal information is information that identifies you or could be combined by us or our service providers and affiliates with other information to identify you. This information includes your personal e-mail address, your home address, your home telephone number, your personal cellphone number and may include your age and/or your image, and other similar information when associated with you. Personal information may also be information containing details as to whether you have opened our promotional e-mails or how you have used our website, if we can associate that personal information with you. 
<br />We collect personal information from you when you create an account, redeem a Cashback Reward, enter a contest or promotion, communicate with us using our web forms to make a request or ask a question, submit a product review, take a survey or otherwise submit personal information to us with your communication. 
<br />We receive personal information about you from other sources. For example, we may also acquire information about you from other promotional or marketing companies with whom you shared your information and whom you have allowed to provide information to us.
<br />
<br />How Do We Use Your Personal Information?
<br />We use your information to provide products and services and to manage our business operations. Some examples include:
<br />
<br />
<br />to register your account;
<br />to authenticate you when you log into your account;
<br />to fulfill your requests for products and services;
<br />to communicate with you about your requests;
<br />to administer contests and promotions that you participate in;
<br />to recommend or to offer you other products and services based on your inferred interests;
<br />to post your product or service reviews;
<br />to communicate with you regarding customer service issues; and
<br />to protect the security or integrity of our websites and our business.
<br />We retain your information for as long as necessary to fulfill the purposes for which we collected the information and/or as required by law.
<br />
<br />
<br />Personalized Offers and Web Analytics
<br />We also automatically collect information when you visit our website in order to customize your experience. For example, we collect information about what site referred you to our website, your browser type, operating system type and version, language, and your Internet Protocol ("IP") address. We also collect information about how you browse through our website, including logging the pages you visit and the amount of time you spend on certain pages. We use this information for a number of technical purposes to ensure that our pages load correctly in your browser, as well as to personalize our offers to you. We also use this information to improve our website. For example:
<br />
<br />
<br />we use IP addresses to correct pages for your geographical location;
<br />we log the frequency of visits to certain pages on our website and how visitors navigate through our website in order to detect problems, improve our website and learn about what products and services are of interest to our customers;
<br />we use information about products that you have clicked on to make suggestions about other products or services that you might be interested in viewing; and
<br />we track whether certain types of promotional e-mails and/or digital advertisements (including but not limited to social media advertisements) were opened by you and whether you sought information about a particular product or service in order to make inferences about other products and services you might be interested in.
<br />
<br />
<br />We also collect information specific to your purchases including:
<br />
<br />Purchase Information -  Information about the items you purchase, including but not limited to item descriptions and quantities, time of purchase, total purchase cost, and the retailer you purchased the item from. Purchase information also includes any information (including personal information) that may be found on a receipt copy that you submit to us when completing an Eclipsa Deal redemption.
<br />
<br />Transaction Information – Information about how you interact with our Eclipsa Deals, including but not limited to the offers you view and redeem, what types of products you purchase, and store locations.
<br />
<br />Social Media - Publicly available information from social media networks. The specific information we obtain depends on the privacy settings you select on your social networks and other factors such as if you use your social network credentials to create an account on our site.
<br />
<br />
<br />
<br />Your Ad Choices: Interest-Based Advertising
<br />Our website uses small pixel files (small images) and cookies (small text files) to keep track of your browser so that you will be recognized when you visit other websites where our third-party ad management providers may show you advertisements from us based on your visit to our website. Some of these advertisements may be customized to particular products and services that you viewed or they may be based on inferences about products and services that you might be interested in. This is called "interest-based advertising".
<br />Please note that if you opt-out of interest-based advertising you may still see advertisements from us on other websites. However, they will not be customized based on your browsing history on our website. In addition, we will continue to customize our offers of products and services to you when you are on our website unless you block cookies from our website. If you block cookies and/or advertisements on our website and/or mobile apps, certain functions will not function properly because our website must recognize you as you move through the rewards submission process. 
<br />We also use third party services, such as Google Analytics, to help us understand how visitors interact with our website and to help improve our user experience. Google provides additional privacy options regarding cookie use described at: www.google.com/policies/privacy/partners/
<br />
<br />Regarding Children and Minors
<br />Our website and the services offered on our website are not directed to children under the age of 16 years. We will not knowingly collect personal information about a child under the age of 16. 
<br />
<br />Do We Disclose Your Personal Information to Others?
<br />We may share your personal information with our service providers, business partners, and our corporate affiliates that help us with our business operations. For example, we will share information about the types of cash back rewards products you purchase to our cash back rewards partners to help create more rewards eligible products to make available to you. We share information with service providers or our affiliates who provide us with marketing and promotional services. We require our service providers and our affiliates to keep your personal information secure. In addition, our service providers may not use or disclose personal information for any purpose other than providing the services on our behalf unless we or they have obtained your consent.
<br />We may transfer any information we have about you in connection with a merger or sale (including transfers made as part of insolvency or bankruptcy proceedings) involving all or part of our business or as part of a corporate reorganization or stock sale or other change in corporate control.
<br />
<br />Law Enforcement Requests
<br />We will disclose personal information without your knowledge or consent if we receive an order, subpoena, warrant or other legal requirement issued by a court, tribunal, regulator or other person with jurisdiction to compel disclosure of your personal information. If we receive a request from a police officer or other law enforcement agency for access to your personal information in the course of an actual or potential investigation, our policy is to require the police officer or law enforcement agency to obtain an order, subpoena or warrant, unless the information is urgently required to prevent serious bodily injury or serious damage to personal property. 
<br />
<br />
<br />Storage Outside of Canada
<br />Your personal information may be used or stored by us or our service providers and our corporate affiliates outside of Canada. We require that our service providers and our corporate affiliates safeguard your personal information. However, if your personal information is used or stored outside of Canada, it will also be subject to the laws of the country in which it is used or stored. 
<br />
<br />
<br />Help Centre
<br />If you register for an account, you will have the option to choose what marketing and promotional e-mails and communications you receive from us. 
<br />You can modify your information by logging into your account and making the requested changes. You cannot modify a product review once it has been submitted. However, you can delete your product review.
<br />You may also choose to close your account and we will remove your account information from active use. Any reviews will be listed as from a "former user".
<br />If you do not re-activate your account within 3 months, we may permanently delete your account. You may also contact us by one of the means set out in the Help Centre to make a deletion request. Please note that we still maintain information that you provided to us for audit purposes or to comply with our legal obligations. We may also de-identify your information in order to continue to use it for research and analytics purposes to understand our business, to make product improvements and to make available to third parties. We prohibit our employees and third parties from attempting to re-identify information that we have de-identified.
<br />
<br />How Do You Access and Modify Your Personal Information or Make a Complaint?
<br />If you have questions or concerns about other personal information please click on the link for the Help Centre on our website or mobile apps to access our help section, submit questions via our ticket systems, or make any available changes to your personal information profile. 
<br />
<br />
<br />How Do We Protect Your Personal Information?
<br />We take administrative, technical and physical measures to safeguard your personal information against unauthorized access, unauthorized disclosure, theft and misuse. This includes limiting access of employees to, and the use of, your personal information through the use of passwords and graduated levels of clearance. We do not publish all of our security measures online because this may reduce their effectiveness. We take physical precautions to ensure that the computer servers on which your personal information is stored and archived are secure and that access to such servers is protected. We educate our employees with respect to their obligations to protect your personal information and we require our affiliates and any third-party service providers to take comparable steps to ensure the protection of any of your personal information that is shared with them.
<br />Although we take precautions against possible breaches of our security systems, no company can fully eliminate the risks of unauthorized access to your personal information and no website is completely secure. We cannot guarantee that unauthorized access, hacking, data loss or breaches of our security systems will never occur. We further do not make any express guarantees about breaches of personal information which may occur by our service providers despite the requirements we place on them to safeguard your personal information in accordance with applicable laws in Canada and elsewhere. Accordingly, you should not transmit personal information to us using our website if you consider that information to be sensitive. 
<br />
<br />Links to Third-Party Websites, Location Information, and Social Network Plug-Ins
<br />You may be able to access third-party websites through links available on our websites. You may also connect to social networks such as Facebook, Instagram, Twitter, etc.. These links are provided for your convenience. We do not have any control over those third-party websites and we do not provide any guarantee that the privacy practices of the hosts of those websites meet our standards. Your use of such third-party websites is at your own risk and will be governed by the privacy policies of those websites and not by this Privacy Notice. Do not transmit personal information using those websites without reading the privacy policies governing those websites.
<br />You may create links between our website and social networking sites. If you do so, we may be able to publish updates about our products and services to your social network account through the links that you establish. You may terminate those links at any time by modifying your social network account.
<br />If you are logged into a social networking site when visiting our page, your visit to our website will be personalized based on your social networking profile.
<br />You may change the way social networking sites and our sites interact by modifying your privacy settings in your social networking account. You can also log-out of your social networking account before visiting our website. For more information, please refer to your social networking site's privacy policy, which governs your use of these social plug-in features.
<br />Additionally, if you have visited our website and/or activated one of our mobile-device applications, and if the settings on your location-aware device allow us to receive GPS and other gegraphic location information, we will collect that automatically. 
<br />
<br />Questions or Comments
<br />If you have any questions or comments about this privacy notice or anything else related to the    Services or Eclipsa Deals, please click on the link in the Help Centre of send us an email at help@eclipsa.com 
<br />
<br /> Last modified: April 1, 2020
<br />
<br />
`,
  },
  fr: {
    privacyTitle: 'Politique de Confidentialité',
    privacySubtitle: 'Politique de Confidentialité d’Eclipsa Inc.',
    privacyContent: `<br />
<br />Chez Eclipsa, nous prenons la confidentialité et la sécurité de vos données au sérieux. Cette Politique de Confidentialité s'applique aux informations personnelles qu’Eclipsa Inc., une société de la province de l’Ontario, dont le siège social est situé au 20, Camden St., bureau 200, Toronto (ON) M5V 1V1, collecte par l’intermédiaire du site www.eclipsa.com et de nos applications mobiles. Vous trouverez ci-dessous les points importants relatifs à nos pratiques de traitement des informations personnelles, suivies de notre Politique de Confidentialité détaillée.
<br />Nos politiques internes et nos pratiques ont été conçues pour respecter la Loi sur la protection des renseignements personnels et les documents électroniques (Canada) et les lois provinciales correspondantes en matière de protection de la vie privée.
<br />Nous nous réservons le droit de modifier cette Politique de Confidentialité de temps à autres. Nos nouvelles conditions seront affichées sur www.eclipsa.com/privacy et sur nos applications mobiles, et vous acceptez d'être lié par toutes nos modifications de ces conditions. 
<br />
<br />Points importants de notre Politique de Confidentialité
<br />
<br />Information que nous collectons
<br />
<br />Nous collectons vos informations à partir des sources suivantes :
<br />
<br />les informations que vous nous communiquez lorsque vous remplissez des formulaires, tels que des achats en ligne, des demandes de service après-vente et des sondages ;
<br />des informations provenant d'autres sources, telles que des sociétés qui nous aident à mettre à jour nos archives ; et
<br />des informations que nous recueillons automatiquement lorsque vous visitez notre site, telles que des informations sur les paramètres de votre navigateur et d'autres informations collectées via des cookies.
<br />
<br />Comment nous utilisons et divulguons vos informations
<br />
<br />Nous utilisons vos informations pour fournir des produits et services et pour gérer notre activité commerciale. Par exemple, nous utilisons vos informations pour effectuer vos Remises, pour répondre à vos demandes de renseignements, pour vous authentifier lorsque vous vous connectez à des services en ligne, pour prévenir les pertes et les fraudes et pour analyser nos opérations commerciales et les performances de votre site.
<br />Nous utilisons vos informations à des fins de marketing et de promotion, y compris avec nos partenaires promotionnels.
<br />Nous combinons toutes les informations que nous collectons autres que celles relatives à votre historique de navigation.
<br />Nous partageons des informations avec nos sociétés affiliées qui peuvent être hors du Canada et être soumises à des lois différentes de celles en vigueur au Canada.
<br />Nous faisons appel à des tiers pour nous aider dans nos opérations et notre activité. Nous pouvons partager vos informations avec ces tiers afin de vous fournir des services.
<br />
<br />Vos choix
<br />
<br />Nous devons vous envoyer certaines notifications pour que nous puissions communiquer avec vous au sujet des services que nous vous proposons, tel qu’indiqué dans nos conditions d’utilisation (les « Conditions ») (www.eclipsa.com/terms), y compris par e-mail, SMS et/ou mobile. Vous acceptez que nous vous envoyions ces notifications. Vous pouvez modifier vos préférences en matière de communication en ce qui concerne les newsletters par courriel et les notifications d'offres spéciales et de produits. Si vous n'êtes pas d'accord ou n'acceptez pas ces termes, vous devez cesser d'utiliser les Services immédiatement.
<br />
<br />Vous pouvez également fermer votre compte si vous souhaitez retirer votre consentement d’une utilisation ultérieure de vos informations. Nous pouvons toutefois conserver des informations sur vos transactions précédentes à des fins d'audit, afin de garantir l'intégrité de nos données et de satisfaire aux exigences légales.
<br />
<br />Nous contacter
<br />
<br />Si vous avez une question ou un problème relatif à la confidentialité de vos informations, cliquez sur le lien du Centre d’assistance de notre site ou de nos applications mobiles pour accéder à notre section Besoin d’aide et/ou poser vos questions.
<br />
<br />
<br />Politique de Confidentialité détaillée
<br />
<br />A propos de nous
<br />
<br />Ce site www.eclipsa.com est exploité par Eclipsa Inc. Nous sommes situés au 20, Camden St., bureau 200, Toronto, ON M5V 1V1.
<br />
<br />Si vous avez une question ou une plainte concernant nos pratiques de traitement des informations personnelles, veuillez cliquer sur le lien du Centre d’assistance de notre site ou de nos applications mobiles pour accéder à notre section Besoin d’aide et/ou poser vos questions.
<br />
<br />Dans cette Politique de Confidentialité, les termes « nous », « nôtre » désignent Eclipsa, Inc. 
<br />
<br />Dans cette Politique de Confidentialité, « vous », « vôtre » désignent la personne qui accède aux Services Eclipsa ou les utilise, tel qu’indiqué dans nos Conditions.
<br />
<br />Date d’entrée en vigueur et champ d’application
<br />
<br />Cette Politique de Confidentialité est en vigueur à compter du [date].
<br />
<br />Cette Politique de Confidentialité régit nos pratiques en ce qui concerne les informations personnelles que nous recueillons sur notre site www.eclipsa.com et nos applications mobiles Eclipsa.
<br />
<br />Quelles informations personnelles nous collectons ?
<br />
<br />Les informations personnelles sont des informations qui vous identifient ou qui pourraient être combinées par nous, nos prestataires de services et nos sociétés affiliées avec d'autres informations permettant de vous identifier. Ces informations comprennent votre courriel personnel, votre adresse personnelle, votre numéro de téléphone personnel, votre numéro de téléphone portable personnel et peuvent inclure votre âge et/ou une image de vous, ainsi que d'autres informations similaires, lorsqu'elles vous sont associées. Les informations personnelles peuvent également contenir des informations indiquant si vous avez ouvert nos courriels promotionnels ou comment vous avez utilisé notre site, si nous pouvons associer ces informations personnelles à vous.
<br />
<br />Nous collectons des informations personnelles vous concernant lorsque vous créez un compte, recevez une Remise, participez à un concours ou à une promotion, communiquez avec nous à l'aide de nos formulaires web pour faire une demande ou poser une question, soumettez un commentaire sur un produit, participez à un sondage ou lorsque vous nous communiquez vos informations personnelles d’une quelconque autre manière.
<br />
<br />Nous recevons des informations personnelles vous concernant provenant d'autres sources. Par exemple, nous pouvons obtenir des informations vous concernant auprès d’autres sociétés de promotion ou de marketing avec lesquelles vous avez partagé vos informations et autorisé la divulgation.
<br />
<br />Comment nous utilisons vos informations personnelles ? 
<br />
<br />Nous utilisons vos informations pour fournir des produits et services et pour gérer notre activité commerciale, et notamment pour : 
<br />
<br />enregistrer votre compte ;
<br />vous authentifier lorsque vous vous connectez à votre compte ;
<br />répondre à vos demandes de produits et services ;
<br />communiquer avec vous au sujet de vos demandes et requêtes ;
<br />administrer les concours et les promotions auxquels vous participez ;
<br />vous recommander ou vous proposer d'autres produits et services en fonction de vos intérêts ;
<br />publier vos critiques/commentaires de produits ou de services ;
<br />pour communiquer avec vous concernant le service client et le service après-vente ; et
<br />protéger la sécurité ou l'intégrité de nos sites et de nos activités.
<br />Nous conservons vos informations aussi longtemps que nécessaire pour atteindre les finalités pour lesquelles nous les avons collectées et/ou tel que requis par la loi.
<br />
<br />Offres personnalisées et analyse comportementale
<br />
<br />Nous recueillons automatiquement des informations lorsque vous visitez notre site afin de personnaliser votre expérience. Par exemple, nous collectons des informations sur les sites qui vous ont mené vers notre site, le type de votre navigateur, le type et la version du système d'exploitation, la langue et votre adresse de protocole Internet (« adresse IP »). Nous recueillons également des informations sur la façon dont vous naviguez sur notre site, y compris sur les pages que vous visitez et le temps que vous passez sur certaines pages. Nous utilisons ces informations à des fins techniques diverses pour nous assurer que nos pages sont correctement chargées dans votre navigateur et pour personnaliser nos offres. 
<br />
<br />Nous utilisons également ces informations pour améliorer notre site. Par exemple : 
<br /> 
<br />nous utilisons les adresses IP pour ajuster les pages web en fonction de votre localisation géographique ; 
<br />nous enregistrons la fréquence des visites sur certaines pages de notre site et comment les visiteurs naviguent sur notre site afin de détecter les problèmes, améliorer notre site et connaître les produits et les services qui intéressent nos clients ; 
<br />nous utilisons les informations relatives aux produits sur lesquels vous avez cliqué pour faire des suggestions sur d'autres produits ou services que vous souhaiteriez consulter ; et
<br />nous vérifions si vous avez ouvert certains types de courriels promotionnels et/ou de publicités digitales (y compris, sans limitation, les publicités de réseaux sociaux), ou si vous avez recherché des informations sur un produit ou un service particulier afin de tirer des conclusions concernant d’autres produits et services qui pourraient vous intéresser. 
<br />
<br />Nous recueillons également des informations spécifiques à vos achats, notamment :
<br />
<br />Informations d'achat – Informations sur les articles que vous achetez, y compris, sans limitation, les descriptions et les quantités d'articles, l'heure d'achat, le coût total d'achat et le revendeur à partir duquel vous avez acheté l'article. Les informations d'achat incluent également toutes les informations (y compris les informations personnelles) pouvant être présentes sur une copie du reçu que vous nous soumettez lors de la réalisation d’une Offre Eclipsa.
<br />
<br />Informations sur les transactions – informations sur la manière dont vous interagissez avec nos Offres Eclipsa, notamment les offres que vous affichez et celles dont vous demander une remise, les types de produits que vous achetez et l’emplacement des magasins.
<br />
<br />Réseaux sociaux – Informations accessibles au public sur les réseaux sociaux. Les informations spécifiques que nous obtenons dépendent des paramètres de confidentialité que vous avez sélectionnés sur vos réseaux sociaux et également d'autres facteurs, par exemple si vous utilisez vos informations d'identification de réseau social pour créer un compte sur notre site.
<br />
<br />Vos choix promotionnels : publicité ciblée
<br />
<br />Notre site utilise des fichiers pixels (petites images) et des cookies (petits fichiers de texte) pour assurer le suivi et la performance de votre navigateur. Ainsi, vous serez reconnu lorsque vous visiterez d'autres sites sur lesquels nos prestataires tiers de gestion d'annonces pourront diffuser des annonces basées sur votre visite de notre site web. Certaines de ces publicités peuvent être personnalisées pour des produits et services particuliers que vous avez visualisés ou peuvent être basées sur des déductions relatives à des produits et services qui pourraient vous intéresser. C'est ce que nous appelons « la publicité ciblée ». 
<br />
<br />Retenez que si vous vous désabonnez des publicités basées sur vos centres d'intérêt, vous pouvez toujours voir des publicités de notre part sur d'autres sites. Cependant, ils ne seront pas personnalisés en fonction de votre historique de navigation sur notre site. De plus, nous continuerons de personnaliser nos offres de produits et services lorsque vous visiterez notre site, à moins que vous bloquiez les cookies de notre site. Si vous bloquez les cookies et/ou les publicités sur notre site et/ou les applications mobiles, certaines fonctionnalités ne fonctionneront pas correctement car notre site doit vous reconnaître lorsque vous suivez le processus de demandes des remises.
<br />
<br />Nous utilisons également des services tiers, tels que « Google Analytics », pour nous aider à comprendre comment les visiteurs interagissent avec notre site et améliorer votre expérience utilisateur. Google propose des options de confidentialité spécifiques concernant l'utilisation de ces cookies, accessibles à l'adresse :https://policies.google.com/technologies/partner-sites?hl=fr
<br />
<br />A propos des enfants et mineurs
<br />
<br />Notre site et les services proposés sur notre site ne sont pas destinés aux enfants de moins de 16 ans. Nous ne collecterons pas sciemment d'informations personnelles relatives aux enfants de moins de 16 ans.
<br />
<br />Divulguons-nous vos informations personnelles à des tiers ? 
<br />
<br />Nous pouvons partager vos informations personnelles avec nos prestataires de services, nos partenaires commerciaux et nos sociétés affiliées, qui nous aident dans nos opérations et notre activité commerciale. 
<br />Par exemple, nous partagerons des informations sur les types de produits que vous achetez avec des possibilités de remises, à nos partenaires des remises, afin de créer plus de produits avec remises à votre disposition. Nous partageons des informations avec des prestataires de services ou nos sociétés affiliées qui nous fournissent des services de marketing et de promotion. Nous demandons à nos prestataires de services et à nos sociétés affiliées de protéger vos informations personnelles. De plus, nos prestataires de services ne peuvent utiliser ou divulguer des informations personnelles à des fins autres que la fourniture des services en notre nom, sauf si nous avons, ou si ces sociétés ont obtenu votre consentement.
<br />
<br />Nous pouvons transférer toutes les informations que nous disposons sur vous dans le cadre d’une fusion ou d’une vente (y compris de transferts effectués dans le cadre de procédures d’insolvabilité ou de faillite) concernant tout ou partie de nos activités, dans le cadre d’une réorganisation ou de la vente d’actions ou de tout autre changement de contrôle de notre société.
<br />
<br />Demandes et requêtes légales
<br />
<br />Nous divulguerons des informations personnelles à votre insu ou sans votre consentement si nous recevons une ordonnance, une assignation à comparaître, un mandat ou toute autre obligation légale émise par un tribunal, un organisme règlementaire ou toute autre personne ou entité compétente pour contraindre la divulgation de vos informations personnelles. 
<br />
<br />Si nous recevons une demande d'un agent de police ou d'un autre organisme chargé de l'application de la loi pour l'accès à vos informations personnelles dans le cadre d'une enquête réelle ou éventuelle, notre politique consiste à exiger de l'agent de police ou de l'organisme d'application de la loi qu'il obtienne un ordre, une assignation ou un mandat, sauf si cette information est requise de toute urgence pour éviter des lésions corporelles graves ou des dommages graves aux biens personnels.
<br />
<br />Hébergement hors du Canada
<br />
<br />Vos informations personnelles peuvent être utilisées ou stockées par nous, nos prestataires de services ou nos sociétés affiliées à l’extérieur du Canada. Nous exigeons que nos prestataires de services et nos sociétés affiliées protègent vos informations personnelles. Toutefois, si vos informations personnelles sont utilisées ou stockées à l'extérieur du Canada, elles seront également soumises aux lois du pays dans lequel elles sont utilisées ou stockées.
<br />
<br />Centre d’assistance
<br />
<br />Si vous enregistrez un compte, vous aurez la possibilité de choisir quels courriels et communications marketing et promotionnels vous voulez recevoir de notre part.
<br />
<br />Vous pouvez modifier vos informations en vous connectant à votre compte et en effectuant les modifications demandées. Vous ne pouvez pas modifier une évaluation de produit une fois qu'elle a été soumise. Cependant, vous pouvez supprimer vos commentaires sur le produit.
<br />
<br />Vous pouvez également choisir de fermer votre compte et nous supprimerons les informations relatives à votre compte pour toute utilisation active. Tous les commentaires seront listés à partir d'un « ancien utilisateur ».
<br />
<br />Si vous ne réactivez pas votre compte dans les 3 mois, nous pouvons le supprimer définitivement. Vous pouvez également nous contacter par l’un des moyens indiqués dans le Centre d’assistance pour faire une demande de suppression. Veuillez noter que nous conservons toujours les informations que vous nous avez fournies à des fins d'audit ou pour nous conformer à nos obligations légales. Nous pouvons également anonymiser vos informations afin de continuer à les utiliser à des fins de recherche et d'analyse pour comprendre notre activité, améliorer les produits et les mettre à la disposition de tiers. Nous interdisons à nos employés et aux tiers d'essayer de ré-identifier les informations que nous avons anonymisées.
<br />
<br />Comment accéder et modifier vos informations personnelles ou former une plainte ? 
<br />
<br />Si vous avez des questions ou des préoccupations concernant vos informations personnelles, veuillez cliquer sur le lien du Centre d'assistance de notre site ou de nos applications mobiles pour accéder à notre section Besoin d'aide, soumettre des questions ou faire toute modification de votre profil ou de vos informations personnelles. 
<br />
<br />Comment nous protégeons vos informations personnelles ?
<br />
<br />Nous prenons des mesures administratives, techniques et physiques pour protéger vos informations personnelles contre les accès non-autorisés, la divulgation non-autorisée, le vol et l’utilisation nuisible de vos informations. Cela inclut la limitation de l’accès des employés à vos informations personnelles, leur utilisation verrouillée par mots de passe et des niveaux d’autorisation progressifs. Nous ne publions pas toutes nos mesures de sécurité en ligne car cela pourrait réduire leur efficacité. Nous prenons des mesures concrètes pour nous assurer que les serveurs sur lesquels vos informations personnelles sont stockées et archivées sont sécurisés et que l'accès à ces serveurs est protégé. Nous formons nos employés sur leurs obligations en matière de protection de vos informations personnelles et nous demandons à nos sociétés affiliées et à tous les prestataires de services tiers de prendre des mesures similaires aux nôtres pour assurer la protection de vos informations personnelles qui leur sont partagées.
<br />
<br />Bien que nous prenions des précautions pour éviter toute violation de nos systèmes de sécurité, aucune entreprise ne peut éliminer complètement ces risques d’accès non-autorisé et aucun site n’est totalement sécurisé. Nous ne pouvons garantir qu'aucun accès non autorisé, piratage informatique, perte de données ou violation de nos systèmes de sécurité ne se produira jamais. De plus, nous ne donnons aucune garantie expresse à propos de la violation des informations personnelles pouvant être commise par nos prestataires de services, malgré les obligations que nous leur imposons pour protéger vos informations personnelles conformément aux lois applicables au Canada et ailleurs. En conséquence, vous ne devez pas nous transmettre d'informations personnelles par le biais de notre site si vous considérez que ces informations sont sensibles.
<br />
<br />Liens vers des sites tiers, informations de localisation et plugiciels de réseau social
<br />
<br />Vous pouvez accéder à des sites tiers via des liens disponibles sur nos sites. Vous pouvez également vous connecter à des réseaux sociaux tels que Facebook, Instagram, Twitter, etc. 
<br />Ces liens sont fournis pour votre commodité. Nous n'avons aucun contrôle sur ces sites tiers et nous ne fournissons aucune garantie que les pratiques de confidentialité des hébergeurs de ces sites tiers respectent nos normes et pratiques. Votre utilisation de ces sites tiers est à vos risques et périls et sera régie par les politiques de confidentialité de ces sites et non par la présente Politique de Confidentialité. Ne transmettez pas d'informations personnelles à l'aide de ces sites sans lire les politiques de confidentialité régissant ces sites.
<br />
<br />Vous pouvez créer des liens entre notre site et les sites de réseaux sociaux. Si vous le faites, nous pourrons publier des mises à jour sur nos produits et services sur votre profil de réseau social via les liens que vous avez créés. Vous pouvez mettre fin à ces liens à tout moment en modifiant votre compte sur le réseau social.
<br />
<br />Si vous êtes connecté à un site de réseau social lorsque vous visitez notre page, votre visite sur notre site sera personnalisée en fonction de votre profil sur ce réseau social.
<br />
<br />Vous pouvez modifier la manière dont les sites de réseaux sociaux et nos sites interagissent en modifiant les paramètres de confidentialité de votre compte de réseau social. Vous pouvez également vous déconnecter de votre compte de réseau social avant de visiter notre site. Pour plus d'informations, reportez-vous à la politique de confidentialité de votre réseau social qui régit votre utilisation de ces fonctionnalités du plugiciel social. 
<br />
<br />De plus, si vous avez visité notre site et/ou activé l'une de nos applications mobiles, et si les paramètres de votre mobile nous autorisent à recevoir votre position GPS et d’autres informations de localisation, nous les collecterons automatiquement.
<br />
<br />Questions ou Commentaires
<br />Si vous avez des questions ou commentaires sur notre politique de confidentialité ou toute question relative aux Services ou autres Offres Eclipsa, veuillez cliquer sur le lien du Centre d’assistance ou nous envoyer un courriel à l'adresse : assistance@eclipsa.com
<br />
<br /> Date de la dernière modification: 1 avril 2020
<br />
<br />`,
  },
}
