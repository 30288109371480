import { toast } from 'react-toastify'
import api from '../services/api'

export const GET_NEW_ALERTS_LOADING = 'GET_NEW_ALERTS_LOADING'
export const GET_NEW_ALERTS_SUCCESS = 'GET_NEW_ALERTS_SUCCESS'
export const GET_NEW_ALERTS_FAILURE = 'GET_NEW_ALERTS_FAILURE'

//get alert msg
export const getNewAlerts = () => (dispatch, getState) => {
  dispatch({ type: GET_NEW_ALERTS_LOADING })

  const token = getState().user.data.token
  return api({
    method: 'POST',
    url: `/alerts/getAlertsForUser`,
    tokenHeaders: {
      authorization: token,
    },
  })
    .then(resp => {
      if ('success' in resp.data && resp.data.success === true) {
        dispatch({
          type: GET_NEW_ALERTS_SUCCESS,
          payload: { data: resp.data },
        })
      } else {
        dispatch({ type: GET_NEW_ALERTS_FAILURE, payload: {} })
        toast.errror(
          'message' in resp.data ? esp.data.message : 'Please try again later'
        )
      }
    })
    .catch(err => {
      console.log('err', err)
      dispatch({
        type: GET_NEW_ALERTS_FAILURE,
        payload: {},
      })
      toast.error('sorry please try later or contact client service')
    })
}
