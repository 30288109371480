import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import 'bootstrap/dist/css/bootstrap.min.css'
import { store, persistor } from './src/store'
import { Helmet } from "react-helmet"

import appLogo from './src/assets/images/eclipsa-logo.png'

// eslint-disable-next-line react/display-name,react/prop-types
export default ({ element }) => {
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  return (
    <Provider store={store}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Eclipsa</title>
        <meta name="description" content="Live Better and Save" />

        {/* <!-- Google / Search Engine Tags --> */}
        <meta itemprop="name" content="Eclipsa" />
        <meta itemprop="description" content="Live Better and Save" />
        <meta itemprop="image" content={appLogo} />

        {/* <!-- Facebook Meta Tags --> */}
        <meta property="og:url" content="https://eclipsa.com" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Eclipsa" />
        <meta property="og:description" content="Live Better and Save" />
        <meta property="og:image" content={appLogo} />

        {/* <!-- Twitter Meta Tags --> */}
        <meta name="twitter:card" content={appLogo} />
        <meta name="twitter:title" content="Eclipsa" />
        <meta name="twitter:description" content="Live Better and Save" />
        <meta name="twitter:image" content={appLogo} />

        <link rel="canonical" href="https://eclipsa.com" />
      </Helmet>

      <PersistGate loading={null} persistor={persistor}>
        {element}
      </PersistGate>
    </Provider>
  )
}
