import {
  REGISTER_LOADING,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  LOGIN_SUCCESS,
  LOGIN_LOADING,
  LOGIN_FAILURE,
  FACEBOOK_LOGIN_LOADING,
  FACEBOOK_LOGIN_SUCCESS,
  FACEBOOK_LOGIN_FAILURE,
  UPDATE_PROFILE_LOADING,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAILURE,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_LOADING,
  CHANGE_PASSWORD_FAILURE,
} from '../actions/user'
import {
  CASH_OUT_CHEQUE_LOADING,
  CASH_OUT_CHEQUE_SUCCESS,
  CASH_OUT_CHEQUE_FAILURE,
  CASH_OUT_EMAIL_LOADING,
  CASH_OUT_EMAIL_SUCCESS,
  CASH_OUT_EMAIL_FAILURE,
} from '../actions/cashout'
import {
  FORGOT_LOADING,
  FORGOT_SUCCESS,
  FORGOT_FAILURE,
  RESET_LOADING,
  RESET_SUCCESS,
  RESET_FAILURE,
} from '../actions/forgot'

// loader are declared here anticipately, states might be useful in the future consideration

const INITIAL_STATE = {
  register: false,
  facebook: false,
  login: false,
  forgot: false,
  reset: false,
  updateProfile: false,
  changePassword: false,
  cashOut: false,
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    //cash out
    case CASH_OUT_CHEQUE_LOADING:
    case CASH_OUT_EMAIL_LOADING: {
      const newState = { ...state, cashOut: true }
      return newState
    }

    case CASH_OUT_CHEQUE_FAILURE:
    case CASH_OUT_CHEQUE_SUCCESS:
    case CASH_OUT_EMAIL_FAILURE:
    case CASH_OUT_EMAIL_SUCCESS: {
      const newState = { ...state, cashOut: false }
      return newState
    }

    //change password
    case CHANGE_PASSWORD_LOADING: {
      const newState = { ...state, updateProfile: true }
      return newState
    }

    case CHANGE_PASSWORD_SUCCESS:
    case CHANGE_PASSWORD_FAILURE: {
      const newState = { ...state, changePassword: false }
      return newState
    }

    //upload profile
    case UPDATE_PROFILE_LOADING: {
      const newState = { ...state, updateProfile: true }
      return newState
    }

    case UPDATE_PROFILE_SUCCESS:
    case UPDATE_PROFILE_FAILURE: {
      const newState = { ...state, updateProfile: false }
      return newState
    }
    //reset password
    case RESET_LOADING: {
      const newState = { ...state, reset: true }
      return newState
    }

    case RESET_SUCCESS:
    case RESET_FAILURE: {
      const newState = { ...state, reset: false }
      return newState
    }

    //forgot password
    case FORGOT_LOADING: {
      const newState = { ...state, forgot: true }
      return newState
    }

    case FORGOT_SUCCESS:
    case FORGOT_FAILURE: {
      const newState = { ...state, forgot: false }
      return newState
    }

    //login:
    case LOGIN_LOADING: {
      const newState = { ...state, login: true }
      return newState
    }

    case LOGIN_SUCCESS:
    case LOGIN_FAILURE: {
      const newState = { ...state, login: false }
      return newState
    }

    case REGISTER_LOADING: {
      const newState = { ...state, register: true }
      return newState
    }

    case REGISTER_SUCCESS:
    case REGISTER_FAILURE: {
      const newState = { ...state, register: false }
      return newState
    }

    case FACEBOOK_LOGIN_LOADING: {
      const newState = { ...state, facebook: true }
      return newState
    }

    case FACEBOOK_LOGIN_SUCCESS:
    case FACEBOOK_LOGIN_FAILURE: {
      const newState = { ...state, facebook: false }
      return newState
    }

    default:
      return state
  }
}
