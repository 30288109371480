import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage' // defaults to localStorage for web

import rootReducer from '../reducers'

const persistConfig = {
  key: 'eclipsa2',
  storage,
  blacklist: ['loading'],
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const composeEnhancers =
  (typeof window !== 'undefined' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose

// Connect our store to the reducers
export const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(thunk))
)
export const persistor = persistStore(store)
