import {
  GET_NEW_ALERTS_SUCCESS,
  GET_NEW_ALERTS_FAILURE,
} from '../actions/alerts'

const INITIAL_STATE = {
  error: false,
  data: {
    alerts: [],
  },
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_NEW_ALERTS_SUCCESS: {
      const newState = { ...state, data: action.payload.data }
      return newState
    }
    case GET_NEW_ALERTS_FAILURE:
    default: {
      return state
    }
  }
}
