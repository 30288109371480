import {
  CASH_OUT_CHEQUE_SUCCESS,
  CASH_OUT_CHEQUE_FAILURE,
  CASH_OUT_EMAIL_SUCCESS,
  CASH_OUT_EMAIL_FAILURE,
} from '../actions/cashout'
import { LOGOUT_USER } from '../actions/user'

const INITIAL_STATE = {
  error: false,
  data: {},
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CASH_OUT_EMAIL_SUCCESS: {
      const newState = {
        ...state,
        data: action.payload,
      }
      return newState
    }

    case CASH_OUT_CHEQUE_SUCCESS: {
      const newState = {
        ...state,
        data: action.payload,
      }
      return newState
    }

    case CASH_OUT_CHEQUE_FAILURE:
    case CASH_OUT_EMAIL_FAILURE:
      return state

    case LOGOUT_USER: {
      return INITIAL_STATE
    }

    default: {
      return state
    }
  }
}
